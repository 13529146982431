(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	else if(typeof define === 'function' && define.amd)
		define([], factory);
	else if(typeof exports === 'object')
		exports["@base39/ui-utils"] = factory();
	else
		root["@base39/ui-utils"] = factory();
})(self, function() {
return 