const colors = {
  prime: {
    50: '#E5F2FE',
    100: '#C1E0FC',
    200: '#96CCFA',
    300: '#66B8F9',
    400: '#4CA7F8',
    500: '#4397F7',
    600: '#3C88F7',
    700: '#3273F6',
    800: '#0062FF',
    900: '#2B3AD7',
  },
  alternate: {
    50: '#E7E9F3',
    100: '#C1C8E2',
    200: '#99A5CD',
    300: '#7081BA',
    400: '#5166AD',
    500: '#2E4DA0',
    600: '#274597',
    700: '#1C3C8C',
    800: '#003385',
    900: '#09216C',
  },
  neutral: {
    0: '#ffffff',
    50: '#F5F5F5',
    100: '#E9E9E9',
    200: '#D9D9D9',
    300: '#C4C4C4',
    400: '#9D9D9D',
    500: '#7B7B7B',
    600: '#555555',
    700: '#434343',
    800: '#262626',
    900: '#000000',
  },
  alert: {
    50: '#FCF3E2',
    100: '#F9E1B7',
    200: '#F5CF8B',
    300: '#F0AC47',
    400: '#F0AC47',
    500: '#EE9E39',
    600: '#EA9335',
    700: '#E38431',
    800: '#EC7000',
    900: '#D25E27',
  },
  warning: {
    50: '#FFFEEB',
    100: '#FEFACC',
    200: '#FDF6AC',
    300: '#FCF18D',
    400: '#F9ED78',
    500: '#F7E865',
    600: '#FADF66',
    700: '#FFC542',
    800: '#F1B253',
    900: '#E78E44',
  },
  error: {
    50: '#FCEDF0',
    100: '#F8D2D6',
    200: '#EAA3A3',
    300: '#E17F7F',
    400: '#FC5A5A',
    500: '#EB584A',
    600: '#E15049',
    700: '#CF4641',
    800: '#C33F3A',
    900: '#B5352F',
  },
  success: {
    50: '#E4F4F0',
    100: '#BDE5D8',
    200: '#94D5C1',
    300: '#6DC3A8',
    400: '#00B894',
    500: '#4CA784',
    600: '#459A77',
    700: '#3D8A68',
    800: '#357959',
    900: '#275C3E',
  },
  complementaryDark: {
    50: '#EEE4F6',
    100: '#D4BEEB',
    200: '#B793DE',
    300: '#A461D8',
    400: '#863FC8',
    500: '#7013BA',
    600: '#6811B4',
    700: '#5B0EAC',
    800: '#500BA4',
    900: '#3D0697',
  },
  complementaryLight: {
    50: '#FAE8F4',
    100: '#F4C4E4',
    200: '#FF9AD5',
    300: '#EE79BF',
    400: '#EC5EAC',
    500: '#EB479A',
    600: '#E04494',
    700: '#C93E8B',
    800: '#B43A84',
    900: '#8F3279',
  }
} as const

export default colors
