export const COUNTRIES = [
  {
      gentile: "afegãne",
      countryName: "Afeganistão",
      internationalCountryName: "Afghanistan",
      acronym: "AF",
  },
  {
      gentile: "sul-africana",
      countryName: "África do Sul",
      internationalCountryName: "South Africa",
      acronym: "ZA",
  },
  {
      gentile: "albanesa",
      countryName: "Albânia",
      internationalCountryName: "Albania",
      acronym: "AL",
  },
  {
      gentile: "alemã",
      countryName: "Alemanha",
      internationalCountryName: "Germany",
      acronym: "DE",
  },
  {
      gentile: "andorrana",
      countryName: "Andorra",
      internationalCountryName: "Andorra",
      acronym: "AD",
  },
  {
      gentile: "angolana",
      countryName: "Angola",
      internationalCountryName: "Angola",
      acronym: "AO",
  },
  {
      gentile: "anguillana",
      countryName: "Anguilla",
      internationalCountryName: "Anguilla",
      acronym: "AI",
  },
  {
      gentile: "de antártida",
      countryName: "Antártida",
      internationalCountryName: "Antarctica",
      acronym: "AQ",
  },
  {
      gentile: "antiguana",
      countryName: "Antígua e Barbuda",
      internationalCountryName: "Antigua & Barbuda",
      acronym: "AG",
  },
  {
      gentile: "saudita",
      countryName: "Arábia Saudita",
      internationalCountryName: "Saudi Arabia",
      acronym: "SA",
  },
  {
      gentile: "argelina",
      countryName: "Argélia",
      internationalCountryName: "Algeria",
      acronym: "DZ",
  },
  {
      gentile: "argentina",
      countryName: "Argentina",
      internationalCountryName: "Argentina",
      acronym: "AR",
  },
  {
      gentile: "armênia",
      countryName: "Armênia",
      internationalCountryName: "Armenia",
      acronym: "AM",
  },
  {
      gentile: "arubana",
      countryName: "Aruba",
      internationalCountryName: "Aruba",
      acronym: "AW",
  },
  {
      gentile: "australiana",
      countryName: "Austrália",
      internationalCountryName: "Australia",
      acronym: "AU",
  },
  {
      gentile: "austríaca",
      countryName: "Áustria",
      internationalCountryName: "Austria",
      acronym: "AT",
  },
  {
      gentile: "azerbaijano",
      countryName: "Azerbaijão",
      internationalCountryName: "Azerbaijan",
      acronym: "AZ",
  },
  {
      gentile: "bahamense",
      countryName: "Bahamas",
      internationalCountryName: "Bahamas",
      acronym: "BS",
  },
  {
      gentile: "barenita",
      countryName: "Bahrein",
      internationalCountryName: "Bahrain",
      acronym: "BH",
  },
  {
      gentile: "bengali",
      countryName: "Bangladesh",
      internationalCountryName: "Bangladesh",
      acronym: "BD",
  },
  {
      gentile: "barbadiana",
      countryName: "Barbados",
      internationalCountryName: "Barbados",
      acronym: "BB",
  },
  {
      gentile: "bielo-russa",
      countryName: "Belarus",
      internationalCountryName: "Belarus",
      acronym: "BY",
  },
  {
      gentile: "belga",
      countryName: "Bélgica",
      internationalCountryName: "Belgium",
      acronym: "BE",
  },
  {
      gentile: "belizenha",
      countryName: "Belize",
      internationalCountryName: "Belize",
      acronym: "BZ",
  },
  {
      gentile: "beninense",
      countryName: "Benin",
      internationalCountryName: "Benin",
      acronym: "BJ",
  },
  {
      gentile: "bermudiana",
      countryName: "Bermudas",
      internationalCountryName: "Bermuda",
      acronym: "BM",
  },
  {
      gentile: "boliviana",
      countryName: "Bolívia",
      internationalCountryName: "Bolivia",
      acronym: "BO",
  },
  {
      gentile: "bósnia",
      countryName: "Bósnia-Herzegóvina",
      internationalCountryName: "Bosnia & Herzegovina",
      acronym: "BA",
  },
  {
      gentile: "betchuana",
      countryName: "Botsuana",
      internationalCountryName: "Botswana",
      acronym: "BW",
  },
  {
      gentile: "brasileira",
      countryName: "Brasil",
      internationalCountryName: "Brazil",
      acronym: "BR",
  },
  {
      gentile: "bruneiana",
      countryName: "Brunei",
      internationalCountryName: "Brunei",
      acronym: "BN",
  },
  {
      gentile: "búlgara",
      countryName: "Bulgária",
      internationalCountryName: "Bulgaria",
      acronym: "BG",
  },
  {
      gentile: "burquinês",
      countryName: "Burkina Fasso",
      internationalCountryName: "Burkina Faso",
      acronym: "BF",
  },
  {
      gentile: "burundinesa",
      countryName: "Burundi",
      internationalCountryName: "Burundi",
      acronym: "BI",
  },
  {
      gentile: "butanesa",
      countryName: "Butão",
      internationalCountryName: "Bhutan",
      acronym: "BT",
  },
  {
      gentile: "cabo-verdiana",
      countryName: "Cabo Verde",
      internationalCountryName: "Cape Verde",
      acronym: "CV",
  },
  {
      gentile: "camaronesa",
      countryName: "Camarões",
      internationalCountryName: "Cameroon",
      acronym: "CM",
  },
  {
      gentile: "cambojana",
      countryName: "Camboja",
      internationalCountryName: "Cambodia",
      acronym: "KH",
  },
  {
      gentile: "canadense",
      countryName: "Canadá",
      internationalCountryName: "Canada",
      acronym: "CA",
  },
  {
      gentile: "canário",
      countryName: "Canárias",
      internationalCountryName: "Canary Islands",
      acronym: "IC",
  },
  {
      gentile: "cazaque",
      countryName: "Cazaquistão",
      internationalCountryName: "Kazakhstan",
      acronym: "KZ",
  },
  {
      gentile: "de ceuta e melilla",
      countryName: "Ceuta e Melilla",
      internationalCountryName: "Ceuta & Melilla",
      acronym: "EA",
  },
  {
      gentile: "chadiana",
      countryName: "Chade",
      internationalCountryName: "Chad",
      acronym: "TD",
  },
  {
      gentile: "chilena",
      countryName: "Chile",
      internationalCountryName: "Chile",
      acronym: "CL",
  },
  {
      gentile: "chinesa",
      countryName: "China",
      internationalCountryName: "China",
      acronym: "CN",
  },
  {
      gentile: "cipriota",
      countryName: "Chipre",
      internationalCountryName: "Cyprus",
      acronym: "CY",
  },
  {
      gentile: "cingapuriana",
      countryName: "Cingapura",
      internationalCountryName: "Singapore",
      acronym: "SG",
  },
  {
      gentile: "colombiana",
      countryName: "Colômbia",
      internationalCountryName: "Colombia",
      acronym: "CO",
  },
  {
      gentile: "comorense",
      countryName: "Comores",
      internationalCountryName: "Comoros",
      acronym: "KM",
  },
  {
      gentile: "congolesa",
      countryName: "Congo",
      internationalCountryName: "Congo (Republic)",
      acronym: "CG",
  },
  {
      gentile: "norte-coreano",
      countryName: "Coréia do Norte",
      internationalCountryName: "North Korea",
      acronym: "KP",
  },
  {
      gentile: "norte-coreana",
      countryName: "Coréia do Sul",
      internationalCountryName: "South Korea",
      acronym: "KR",
  },
  {
      gentile: "marfinense",
      countryName: "Costa do Marfim",
      internationalCountryName: "Côte d¿Ivoire",
      acronym: "CI",
  },
  {
      gentile: "costarriquenha",
      countryName: "Costa Rica",
      internationalCountryName: "Costa Rica",
      acronym: "CR",
  },
  {
      gentile: "croata",
      countryName: "Croácia",
      internationalCountryName: "Croatia",
      acronym: "HR",
  },
  {
      gentile: "cubana",
      countryName: "Cuba",
      internationalCountryName: "Cuba",
      acronym: "CU",
  },
  {
      gentile: "curaçauense",
      countryName: "Curaçao",
      internationalCountryName: "Curaçao",
      acronym: "CW",
  },
  {
      gentile: "chagositano",
      countryName: "Diego Garcia",
      internationalCountryName: "Diego Garcia",
      acronym: "DG",
  },
  {
      gentile: "dinamarquesa",
      countryName: "Dinamarca",
      internationalCountryName: "Denmark",
      acronym: "DK",
  },
  {
      gentile: "djibutiana",
      countryName: "Djibuti",
      internationalCountryName: "Djibouti",
      acronym: "DJ",
  },
  {
      gentile: "dominiquense",
      countryName: "Dominica",
      internationalCountryName: "Dominica",
      acronym: "DM",
  },
  {
      gentile: "egípcia",
      countryName: "Egito",
      internationalCountryName: "Egypt",
      acronym: "EG",
  },
  {
      gentile: "salvadorenha",
      countryName: "El Salvador",
      internationalCountryName: "El Salvador",
      acronym: "SV",
  },
  {
      gentile: "árabe",
      countryName: "Emirados Árabes Unidos",
      internationalCountryName: "United Arab Emirates",
      acronym: "AE",
  },
  {
      gentile: "equatoriana",
      countryName: "Equador",
      internationalCountryName: "Ecuador",
      acronym: "EC",
  },
  {
      gentile: "eritreia",
      countryName: "Eritréia",
      internationalCountryName: "Eritrea",
      acronym: "ER",
  },
  {
      gentile: "eslovaco",
      countryName: "Eslováquia",
      internationalCountryName: "Slovakia",
      acronym: "SK",
  },
  {
      gentile: "esloveno",
      countryName: "Eslovênia",
      internationalCountryName: "Slovenia",
      acronym: "SI",
  },
  {
      gentile: "espanhola",
      countryName: "Espanha",
      internationalCountryName: "Spain",
      acronym: "ES",
  },
  {
      gentile: "norte-americana",
      countryName: "Estados Unidos",
      internationalCountryName: "United States",
      acronym: "US",
  },
  {
      gentile: "estoniana",
      countryName: "Estônia",
      internationalCountryName: "Estonia",
      acronym: "EE",
  },
  {
      gentile: "etíope",
      countryName: "Etiópia",
      internationalCountryName: "Ethiopia",
      acronym: "ET",
  },
  {
      gentile: "fijiana",
      countryName: "Fiji",
      internationalCountryName: "Fiji",
      acronym: "FJ",
  },
  {
      gentile: "filipina",
      countryName: "Filipinas",
      internationalCountryName: "Philippines",
      acronym: "PH",
  },
  {
      gentile: "finlandesa",
      countryName: "Finlândia",
      internationalCountryName: "Finland",
      acronym: "FI",
  },
  {
      gentile: "francesa",
      countryName: "França",
      internationalCountryName: "France",
      acronym: "FR",
  },
  {
      gentile: "gabonesa",
      countryName: "Gabão",
      internationalCountryName: "Gabon",
      acronym: "GA",
  },
  {
      gentile: "gambiana",
      countryName: "Gâmbia",
      internationalCountryName: "Gambia",
      acronym: "GM",
  },
  {
      gentile: "ganense",
      countryName: "Gana",
      internationalCountryName: "Ghana",
      acronym: "GH",
  },
  {
      gentile: "georgiana",
      countryName: "Geórgia",
      internationalCountryName: "Georgia",
      acronym: "GE",
  },
  {
      gentile: "gibraltariana",
      countryName: "Gibraltar",
      internationalCountryName: "Gibraltar",
      acronym: "GI",
  },
  {
      gentile: "inglesa",
      countryName: "Grã-Bretanha (Reino Unido, UK)",
      internationalCountryName: "United Kingdom",
      acronym: "GB",
  },
  {
      gentile: "granadina",
      countryName: "Granada",
      internationalCountryName: "Grenada",
      acronym: "GD",
  },
  {
      gentile: "grega",
      countryName: "Grécia",
      internationalCountryName: "Greece",
      acronym: "GR",
  },
  {
      gentile: "groenlandesa",
      countryName: "Groelândia",
      internationalCountryName: "Greenland",
      acronym: "GL",
  },
  {
      gentile: "guadalupense",
      countryName: "Guadalupe",
      internationalCountryName: "Guadeloupe",
      acronym: "GP",
  },
  {
      gentile: "guamês",
      countryName: "Guam (Território dos Estados Unidos)",
      internationalCountryName: "Guam",
      acronym: "GU",
  },
  {
      gentile: "guatemalteca",
      countryName: "Guatemala",
      internationalCountryName: "Guatemala",
      acronym: "GT",
  },
  {
      gentile: "guernesiano",
      countryName: "Guernsey",
      internationalCountryName: "Guernsey",
      acronym: "GG",
  },
  {
      gentile: "guianense",
      countryName: "Guiana",
      internationalCountryName: "Guyana",
      acronym: "GY",
  },
  {
      gentile: "franco-guianense",
      countryName: "Guiana Francesa",
      internationalCountryName: "French Guiana",
      acronym: "GF",
  },
  {
      gentile: "guinéu-equatoriano ou equatoguineana",
      countryName: "Guiné",
      internationalCountryName: "Guinea",
      acronym: "GN",
  },
  {
      gentile: "guinéu-equatoriano",
      countryName: "Guiné Equatorial",
      internationalCountryName: "Equatorial Guinea",
      acronym: "GQ",
  },
  {
      gentile: "guineense",
      countryName: "Guiné-Bissau",
      internationalCountryName: "Guinea-Bissau",
      acronym: "GW",
  },
  {
      gentile: "haitiana",
      countryName: "Haiti",
      internationalCountryName: "Haiti",
      acronym: "HT",
  },
  {
      gentile: "holandês",
      countryName: "Holanda",
      internationalCountryName: "Netherlands",
      acronym: "NL",
  },
  {
      gentile: "hondurenha",
      countryName: "Honduras",
      internationalCountryName: "Honduras",
      acronym: "HN",
  },
  {
      gentile: "hong-konguiana ou chinesa",
      countryName: "Hong Kong",
      internationalCountryName: "Hong Kong",
      acronym: "HK",
  },
  {
      gentile: "húngara",
      countryName: "Hungria",
      internationalCountryName: "Hungary",
      acronym: "HU",
  },
  {
      gentile: "iemenita",
      countryName: "Iêmen",
      internationalCountryName: "Yemen",
      acronym: "YE",
  },
  {
      gentile: "da ilha bouvet",
      countryName: "Ilha Bouvet",
      internationalCountryName: "Bouvet Island",
      acronym: "BV",
  },
  {
      gentile: "da ilha de ascensão",
      countryName: "Ilha de Ascensão",
      internationalCountryName: "Ascension Island",
      acronym: "AC",
  },
  {
      gentile: "da ilha de clipperton",
      countryName: "Ilha de Clipperton",
      internationalCountryName: "Clipperton Island",
      acronym: "CP",
  },
  {
      gentile: "manês",
      countryName: "Ilha de Man",
      internationalCountryName: "Isle of Man",
      acronym: "IM",
  },
  {
      gentile: "natalense",
      countryName: "Ilha Natal",
      internationalCountryName: "Christmas Island",
      acronym: "CX",
  },
  {
      gentile: "pitcairnense",
      countryName: "Ilha Pitcairn",
      internationalCountryName: "Pitcairn Islands",
      acronym: "PN",
  },
  {
      gentile: "reunionense",
      countryName: "Ilha Reunião",
      internationalCountryName: "Réunion",
      acronym: "RE",
  },
  {
      gentile: "alandês",
      countryName: "Ilhas Aland",
      internationalCountryName: "Åland Islands",
      acronym: "AX",
  },
  {
      gentile: "caimanês",
      countryName: "Ilhas Cayman",
      internationalCountryName: "Cayman Islands",
      acronym: "KY",
  },
  {
      gentile: "coquense",
      countryName: "Ilhas Cocos",
      internationalCountryName: "Cocos (Keeling) Islands",
      acronym: "CC",
  },
  {
      gentile: "cookense",
      countryName: "Ilhas Cook",
      internationalCountryName: "Cook Islands",
      acronym: "CK",
  },
  {
      gentile: "faroense",
      countryName: "Ilhas Faroes",
      internationalCountryName: "Faroe Islands",
      acronym: "FO",
  },
  {
      gentile: "das ilhas geórgia do sul e sandwich do sul",
      countryName: "Ilhas Geórgia do Sul e Sandwich do Sul",
      internationalCountryName: "South Georgia & South Sandwich Islands",
      acronym: "GS",
  },
  {
      gentile: "das ilhas heard e mcdonald",
      countryName: "Ilhas Heard e McDonald (Território da Austrália)",
      internationalCountryName: "Heard & McDonald Islands",
      acronym: "HM",
  },
  {
      gentile: "malvinense",
      countryName: "Ilhas Malvinas",
      internationalCountryName: "Falkland Islands (Islas Malvinas)",
      acronym: "FK",
  },
  {
      gentile: "norte-marianense",
      countryName: "Ilhas Marianas do Norte",
      internationalCountryName: "Northern Mariana Islands",
      acronym: "MP",
  },
  {
      gentile: "marshallino",
      countryName: "Ilhas Marshall",
      internationalCountryName: "Marshall Islands",
      acronym: "MH",
  },
  {
      gentile: "das ilhas menores afastadas",
      countryName: "Ilhas Menores dos Estados Unidos",
      internationalCountryName: "U.S. Outlying Islands",
      acronym: "UM",
  },
  {
      gentile: "norfolquino",
      countryName: "Ilhas Norfolk",
      internationalCountryName: "Norfolk Island",
      acronym: "NF",
  },
  {
      gentile: "salomônico",
      countryName: "Ilhas Salomão",
      internationalCountryName: "Solomon Islands",
      acronym: "SB",
  },
  {
      gentile: "seichelense",
      countryName: "Ilhas Seychelles",
      internationalCountryName: "Seychelles",
      acronym: "SC",
  },
  {
      gentile: "toquelauano",
      countryName: "Ilhas Tokelau",
      internationalCountryName: "Tokelau",
      acronym: "TK",
  },
  {
      gentile: "turquês",
      countryName: "Ilhas Turks e Caicos",
      internationalCountryName: "Turks & Caicos Islands",
      acronym: "TC",
  },
  {
      gentile: "virginense",
      countryName: "Ilhas Virgens (Estados Unidos)",
      internationalCountryName: "U.S. Virgin Islands",
      acronym: "VI",
  },
  {
      gentile: "virginense",
      countryName: "Ilhas Virgens (Inglaterra)",
      internationalCountryName: "British Virgin Islands",
      acronym: "VG",
  },
  {
      gentile: "indiano",
      countryName: "Índia",
      internationalCountryName: "India",
      acronym: "IN",
  },
  {
      gentile: "indonésia",
      countryName: "Indonésia",
      internationalCountryName: "Indonesia",
      acronym: "ID",
  },
  {
      gentile: "iraniano",
      countryName: "Irã",
      internationalCountryName: "Iran",
      acronym: "IR",
  },
  {
      gentile: "iraquiana",
      countryName: "Iraque",
      internationalCountryName: "Iraq",
      acronym: "IQ",
  },
  {
      gentile: "irlandesa",
      countryName: "Irlanda",
      internationalCountryName: "Ireland",
      acronym: "IE",
  },
  {
      gentile: "islandesa",
      countryName: "Islândia",
      internationalCountryName: "Iceland",
      acronym: "IS",
  },
  {
      gentile: "israelense",
      countryName: "Israel",
      internationalCountryName: "Israel",
      acronym: "IL",
  },
  {
      gentile: "italiana",
      countryName: "Itália",
      internationalCountryName: "Italy",
      acronym: "IT",
  },
  {
      gentile: "jamaicana",
      countryName: "Jamaica",
      internationalCountryName: "Jamaica",
      acronym: "JM",
  },
  {
      gentile: "japonesa",
      countryName: "Japão",
      internationalCountryName: "Japan",
      acronym: "JP",
  },
  {
      gentile: "canalina",
      countryName: "Jersey",
      internationalCountryName: "Jersey",
      acronym: "JE",
  },
  {
      gentile: "jordaniana",
      countryName: "Jordânia",
      internationalCountryName: "Jordan",
      acronym: "JO",
  },
  {
      gentile: "kiribatiana",
      countryName: "Kiribati",
      internationalCountryName: "Kiribati",
      acronym: "KI",
  },
  {
      gentile: "kosovar",
      countryName: "Kosovo",
      internationalCountryName: "Kosovo",
      acronym: "XK",
  },
  {
      gentile: "kuwaitiano",
      countryName: "Kuait",
      internationalCountryName: "Kuwait",
      acronym: "KW",
  },
  {
      gentile: "laosiana",
      countryName: "Laos",
      internationalCountryName: "Laos",
      acronym: "LA",
  },
  {
      gentile: "lesota",
      countryName: "Lesoto",
      internationalCountryName: "Lesotho",
      acronym: "LS",
  },
  {
      gentile: "letão",
      countryName: "Letônia",
      internationalCountryName: "Latvia",
      acronym: "LV",
  },
  {
      gentile: "libanesa",
      countryName: "Líbano",
      internationalCountryName: "Lebanon",
      acronym: "LB",
  },
  {
      gentile: "liberiana",
      countryName: "Libéria",
      internationalCountryName: "Liberia",
      acronym: "LR",
  },
  {
      gentile: "líbia",
      countryName: "Líbia",
      internationalCountryName: "Libya",
      acronym: "LY",
  },
  {
      gentile: "liechtensteiniense",
      countryName: "Liechtenstein",
      internationalCountryName: "Liechtenstein",
      acronym: "LI",
  },
  {
      gentile: "lituana",
      countryName: "Lituânia",
      internationalCountryName: "Lithuania",
      acronym: "LT",
  },
  {
      gentile: "luxemburguesa",
      countryName: "Luxemburgo",
      internationalCountryName: "Luxembourg",
      acronym: "LU",
  },
  {
      gentile: "macauense",
      countryName: "Macau",
      internationalCountryName: "Macau",
      acronym: "MO",
  },
  {
      gentile: "macedônio",
      countryName: "Macedônia (República Yugoslava)",
      internationalCountryName: "Macedonia (FYROM)",
      acronym: "MK",
  },
  {
      gentile: "malgaxe",
      countryName: "Madagascar",
      internationalCountryName: "Madagascar",
      acronym: "MG",
  },
  {
      gentile: "malaia",
      countryName: "Malásia",
      internationalCountryName: "Malaysia",
      acronym: "MY",
  },
  {
      gentile: "malauiano",
      countryName: "Malaui",
      internationalCountryName: "Malawi",
      acronym: "MW",
  },
  {
      gentile: "maldívia",
      countryName: "Maldivas",
      internationalCountryName: "Maldives",
      acronym: "MV",
  },
  {
      gentile: "malinesa",
      countryName: "Mali",
      internationalCountryName: "Mali",
      acronym: "ML",
  },
  {
      gentile: "maltesa",
      countryName: "Malta",
      internationalCountryName: "Malta",
      acronym: "MT",
  },
  {
      gentile: "marroquina",
      countryName: "Marrocos",
      internationalCountryName: "Morocco",
      acronym: "MA",
  },
  {
      gentile: "martiniquense",
      countryName: "Martinica",
      internationalCountryName: "Martinique",
      acronym: "MQ",
  },
  {
      gentile: "mauriciana",
      countryName: "Maurício",
      internationalCountryName: "Mauritius",
      acronym: "MU",
  },
  {
      gentile: "mauritana",
      countryName: "Mauritânia",
      internationalCountryName: "Mauritania",
      acronym: "MR",
  },
  {
      gentile: "maiotense",
      countryName: "Mayotte",
      internationalCountryName: "Mayotte",
      acronym: "YT",
  },
  {
      gentile: "mexicana",
      countryName: "México",
      internationalCountryName: "Mexico",
      acronym: "MX",
  },
  {
      gentile: "micronésia",
      countryName: "Micronésia",
      internationalCountryName: "Micronesia",
      acronym: "FM",
  },
  {
      gentile: "moçambicana",
      countryName: "Moçambique",
      internationalCountryName: "Mozambique",
      acronym: "MZ",
  },
  {
      gentile: "moldavo",
      countryName: "Moldova",
      internationalCountryName: "Moldova",
      acronym: "MD",
  },
  {
      gentile: "monegasca",
      countryName: "Mônaco",
      internationalCountryName: "Monaco",
      acronym: "MC",
  },
  {
      gentile: "mongol",
      countryName: "Mongólia",
      internationalCountryName: "Mongolia",
      acronym: "MN",
  },
  {
      gentile: "montenegra",
      countryName: "Montenegro",
      internationalCountryName: "Montenegro",
      acronym: "ME",
  },
  {
      gentile: "montserratiano",
      countryName: "Montserrat",
      internationalCountryName: "Montserrat",
      acronym: "MS",
  },
  {
      gentile: "birmanês",
      countryName: "Myanma",
      internationalCountryName: "Myanmar (Burma)",
      acronym: "MM",
  },
  {
      gentile: "namíbia",
      countryName: "Namíbia",
      internationalCountryName: "Namibia",
      acronym: "NA",
  },
  {
      gentile: "nauruana",
      countryName: "Nauru",
      internationalCountryName: "Nauru",
      acronym: "NR",
  },
  {
      gentile: "nepalesa",
      countryName: "Nepal",
      internationalCountryName: "Nepal",
      acronym: "NP",
  },
  {
      gentile: "nicaraguense",
      countryName: "Nicarágua",
      internationalCountryName: "Nicaragua",
      acronym: "NI",
  },
  {
      gentile: "nigerina",
      countryName: "Níger",
      internationalCountryName: "Niger",
      acronym: "NE",
  },
  {
      gentile: "nigeriana",
      countryName: "Nigéria",
      internationalCountryName: "Nigeria",
      acronym: "NG",
  },
  {
      gentile: "niueana",
      countryName: "Niue",
      internationalCountryName: "Niue",
      acronym: "NU",
  },
  {
      gentile: "norueguesa",
      countryName: "Noruega",
      internationalCountryName: "Norway",
      acronym: "NO",
  },
  {
      gentile: "caledônia",
      countryName: "Nova Caledônia",
      internationalCountryName: "New Caledonia",
      acronym: "NC",
  },
  {
      gentile: "neozelandesa",
      countryName: "Nova Zelândia",
      internationalCountryName: "New Zealand",
      acronym: "NZ",
  },
  {
      gentile: "omani",
      countryName: "Omã",
      internationalCountryName: "Oman",
      acronym: "OM",
  },
  {
      gentile: "dos países baixos caribenhos",
      countryName: "Países Baixos Caribenhos",
      internationalCountryName: "Caribbean Netherlands",
      acronym: "BQ",
  },
  {
      gentile: "palauense",
      countryName: "Palau",
      internationalCountryName: "Palau",
      acronym: "PW",
  },
  {
      gentile: "palestino",
      countryName: "Palestina",
      internationalCountryName: "Palestine",
      acronym: "PS",
  },
  {
      gentile: "zona do canal do panamá",
      countryName: "Panamá",
      internationalCountryName: "Panama",
      acronym: "PA",
  },
  {
      gentile: "pauásia",
      countryName: "Papua-Nova Guiné",
      internationalCountryName: "Papua New Guinea",
      acronym: "PG",
  },
  {
      gentile: "paquistanesa",
      countryName: "Paquistão",
      internationalCountryName: "Pakistan",
      acronym: "PK",
  },
  {
      gentile: "paraguaia",
      countryName: "Paraguai",
      internationalCountryName: "Paraguay",
      acronym: "PY",
  },
  {
      gentile: "peruana",
      countryName: "Peru",
      internationalCountryName: "Peru",
      acronym: "PE",
  },
  {
      gentile: "franco-polinésia",
      countryName: "Polinésia Francesa",
      internationalCountryName: "French Polynesia",
      acronym: "PF",
  },
  {
      gentile: "polonesa",
      countryName: "Polônia",
      internationalCountryName: "Poland",
      acronym: "PL",
  },
  {
      gentile: "portorriquenha",
      countryName: "Porto Rico",
      internationalCountryName: "Puerto Rico",
      acronym: "PR",
  },
  {
      gentile: "portuguesa",
      countryName: "Portugal",
      internationalCountryName: "Portugal",
      acronym: "PT",
  },
  {
      gentile: "catarense",
      countryName: "Qatar",
      internationalCountryName: "Qatar",
      acronym: "QA",
  },
  {
      gentile: "queniano",
      countryName: "Quênia",
      internationalCountryName: "Kenya",
      acronym: "KE",
  },
  {
      gentile: "quirguiz",
      countryName: "Quirguistão",
      internationalCountryName: "Kyrgyzstan",
      acronym: "KG",
  },
  {
      gentile: "centro-africana",
      countryName: "República Centro-Africana",
      internationalCountryName: "Central African Republic",
      acronym: "CF",
  },
  {
      gentile: "congolesa",
      countryName: "República Democrática do Congo",
      internationalCountryName: "Congo (DRC)",
      acronym: "CD",
  },
  {
      gentile: "dominicana",
      countryName: "República Dominicana",
      internationalCountryName: "Dominican Republic",
      acronym: "DO",
  },
  {
      gentile: "tcheco",
      countryName: "República Tcheca",
      internationalCountryName: "Czech Republic",
      acronym: "CZ",
  },
  {
      gentile: "romena",
      countryName: "Romênia",
      internationalCountryName: "Romania",
      acronym: "RO",
  },
  {
      gentile: "ruandesa",
      countryName: "Ruanda",
      internationalCountryName: "Rwanda",
      acronym: "RW",
  },
  {
      gentile: "russa",
      countryName: "Rússia (antiga URSS) - Federação Russa",
      internationalCountryName: "Russia",
      acronym: "RU",
  },
  {
      gentile: "saariano",
      countryName: "Saara Ocidental",
      internationalCountryName: "Western Sahara",
      acronym: "EH",
  },
  {
      gentile: "pedro-miquelonense",
      countryName: "Saint-Pierre e Miquelon",
      internationalCountryName: "St. Pierre & Miquelon",
      acronym: "PM",
  },
  {
      gentile: "samoana",
      countryName: "Samoa Americana",
      internationalCountryName: "American Samoa",
      acronym: "AS",
  },
  {
      gentile: "samoano",
      countryName: "Samoa Ocidental",
      internationalCountryName: "Samoa",
      acronym: "WS",
  },
  {
      gentile: "samarinês",
      countryName: "San Marino",
      internationalCountryName: "San Marino",
      acronym: "SM",
  },
  {
      gentile: "helenense",
      countryName: "Santa Helena",
      internationalCountryName: "St. Helena",
      acronym: "SH",
  },
  {
      gentile: "santa-lucense",
      countryName: "Santa Lúcia",
      internationalCountryName: "St. Lucia",
      acronym: "LC",
  },
  {
      gentile: "são-bartolomeense",
      countryName: "São Bartolomeu",
      internationalCountryName: "St. Barthélemy",
      acronym: "BL",
  },
  {
      gentile: "são-cristovense",
      countryName: "São Cristóvão e Névis",
      internationalCountryName: "St. Kitts & Nevis",
      acronym: "KN",
  },
  {
      gentile: "são-martinhense",
      countryName: "São Martim",
      internationalCountryName: "St. Martin",
      acronym: "MF",
  },
  {
      gentile: "são-martinhense",
      countryName: "São Martinho",
      internationalCountryName: "Sint Maarten",
      acronym: "SX",
  },
  {
      gentile: "são-tomense",
      countryName: "São Tomé e Príncipe",
      internationalCountryName: "São Tomé & Príncipe",
      acronym: "ST",
  },
  {
      gentile: "sao-vicentino",
      countryName: "São Vicente e Granadinas",
      internationalCountryName: "St. Vincent & Grenadines",
      acronym: "VC",
  },
  {
      gentile: "senegalesa",
      countryName: "Senegal",
      internationalCountryName: "Senegal",
      acronym: "SN",
  },
  {
      gentile: "leonesa",
      countryName: "Serra Leoa",
      internationalCountryName: "Sierra Leone",
      acronym: "SL",
  },
  {
      gentile: "sérvia",
      countryName: "Sérvia",
      internationalCountryName: "Serbia",
      acronym: "RS",
  },
  {
      gentile: "síria",
      countryName: "Síria",
      internationalCountryName: "Syria",
      acronym: "SY",
  },
  {
      gentile: "somali",
      countryName: "Somália",
      internationalCountryName: "Somalia",
      acronym: "SO",
  },
  {
      gentile: "cingalesa",
      countryName: "Sri Lanka",
      internationalCountryName: "Sri Lanka",
      acronym: "LK",
  },
  {
      gentile: "suazi",
      countryName: "Suazilândia",
      internationalCountryName: "Swaziland",
      acronym: "SZ",
  },
  {
      gentile: "sudanesa",
      countryName: "Sudão",
      internationalCountryName: "Sudan",
      acronym: "SD",
  },
  {
      gentile: "sul-sudanês",
      countryName: "Sudão do Sul",
      internationalCountryName: "South Sudan",
      acronym: "SS",
  },
  {
      gentile: "sueca",
      countryName: "Suécia",
      internationalCountryName: "Sweden",
      acronym: "SE",
  },
  {
      gentile: "suíça",
      countryName: "Suíça",
      internationalCountryName: "Switzerland",
      acronym: "CH",
  },
  {
      gentile: "surinamesa",
      countryName: "Suriname",
      internationalCountryName: "Suriname",
      acronym: "SR",
  },
  {
      gentile: "svalbardense",
      countryName: "Svalbard",
      internationalCountryName: "Svalbard & Jan Mayen",
      acronym: "SJ",
  },
  {
      gentile: "tadjique",
      countryName: "Tadjiquistão",
      internationalCountryName: "Tajikistan",
      acronym: "TJ",
  },
  {
      gentile: "tailandesa",
      countryName: "Tailândia",
      internationalCountryName: "Thailand",
      acronym: "TH",
  },
  {
      gentile: "taiwanês",
      countryName: "Taiwan",
      internationalCountryName: "Taiwan",
      acronym: "TW",
  },
  {
      gentile: "tanzaniana",
      countryName: "Tanzânia",
      internationalCountryName: "Tanzania",
      acronym: "TZ",
  },
  {
      gentile: "do território britânico do oceano índico",
      countryName: "Território Britânico do Oceano índico",
      internationalCountryName: "British Indian Ocean Territory",
      acronym: "IO",
  },
  {
      gentile: "do territórios do sul da frança",
      countryName: "Territórios do Sul da França",
      internationalCountryName: "French Southern Territories",
      acronym: "TF",
  },
  {
      gentile: "timorense",
      countryName: "Timor-Leste",
      internationalCountryName: "Timor-Leste",
      acronym: "TL",
  },
  {
      gentile: "togolesa",
      countryName: "Togo",
      internationalCountryName: "Togo",
      acronym: "TG",
  },
  {
      gentile: "tonganesa",
      countryName: "Tonga",
      internationalCountryName: "Tonga",
      acronym: "TO",
  },
  {
      gentile: "trinitário-tobagense",
      countryName: "Trinidad e Tobago",
      internationalCountryName: "Trinidad & Tobago",
      acronym: "TT",
  },
  {
      gentile: "tristanita",
      countryName: "Tristão da Cunha",
      internationalCountryName: "Tristan da Cunha",
      acronym: "TA",
  },
  {
      gentile: "tunisiana",
      countryName: "Tunísia",
      internationalCountryName: "Tunisia",
      acronym: "TN",
  },
  {
      gentile: "turcomana",
      countryName: "Turcomenistão",
      internationalCountryName: "Turkmenistan",
      acronym: "TM",
  },
  {
      gentile: "turca",
      countryName: "Turquia",
      internationalCountryName: "Turkey",
      acronym: "TR",
  },
  {
      gentile: "tuvaluana",
      countryName: "Tuvalu",
      internationalCountryName: "Tuvalu",
      acronym: "TV",
  },
  {
      gentile: "ucraniana",
      countryName: "Ucrânia",
      internationalCountryName: "Ukraine",
      acronym: "UA",
  },
  {
      gentile: "ugandense",
      countryName: "Uganda",
      internationalCountryName: "Uganda",
      acronym: "UG",
  },
  {
      gentile: "uruguaia",
      countryName: "Uruguai",
      internationalCountryName: "Uruguay",
      acronym: "UY",
  },
  {
      gentile: "uzbeque",
      countryName: "Uzbequistão",
      internationalCountryName: "Uzbekistan",
      acronym: "UZ",
  },
  {
      gentile: "vanuatuense",
      countryName: "Vanuatu",
      internationalCountryName: "Vanuatu",
      acronym: "VU",
  },
  {
      gentile: "vaticano",
      countryName: "Vaticano",
      internationalCountryName: "Vatican City",
      acronym: "VA",
  },
  {
      gentile: "venezuelana",
      countryName: "Venezuela",
      internationalCountryName: "Venezuela",
      acronym: "VE",
  },
  {
      gentile: "vietnamita",
      countryName: "Vietnã",
      internationalCountryName: "Vietnam",
      acronym: "VN",
  },
  {
      gentile: "wallis-futunense",
      countryName: "Wallis e Futuna",
      internationalCountryName: "Wallis & Futuna",
      acronym: "WF",
  },
  {
      gentile: "zambiana",
      countryName: "Zâmbia",
      internationalCountryName: "Zambia",
      acronym: "ZM",
  },
  {
      gentile: "zimbabuana",
      countryName: "Zimbábue",
      internationalCountryName: "Zimbabwe",
      acronym: "ZW",
  },
] as const
