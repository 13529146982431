export const DDDS_BRAZIL = [
    {
      code: '11',
      city: 'São Paulo',
      uf: 'SP',
    },
    {
      code: '12',
      city: 'São José dos Campos',
      uf: 'SP',
    },
    {
      code: '13',
      city: 'Santos',
      uf: 'SP',
    },
    {
      code: '14',
      city: 'Bauru',
      uf: 'SP',
    },
    {
      code: '15',
      city: 'Sorocaba',
      uf: 'SP',
    },
    {
      code: '16',
      city: 'Ribeirão Preto',
      uf: 'SP',
    },
    {
      code: '17',
      city: 'São José do Rio Preto',
      uf: 'SP',
    },
    {
      code: '18',
      city: 'Presidente Prudente',
      uf: 'SP',
    },
    {
      code: '19',
      city: 'Campinas',
      uf: 'SP',
    },
    {
      code: '21',
      city: 'Rio de Janeiro',
      uf: 'RJ',
    },
    {
      code: '22',
      city: 'Campos dos Goytacazes',
      uf: 'RJ',
    },
    {
      code: '24',
      city: 'Volta Redonda',
      uf: 'RJ',
    },
    {
      code: '27',
      city: 'Vila Velha/Vitória',
      uf: 'ES',
    },
    {
      code: '28',
      city: 'Cachoeiro de Itapemirim',
      uf: 'ES',
    },
    {
      code: '31',
      city: 'Belo Horizonte',
      uf: 'MG',
    },
    {
      code: '32',
      city: 'Juiz de Fora',
      uf: 'MG',
    },
    {
      code: '33',
      city: 'Governador Valadares',
      uf: 'MG',
    },
    {
      code: '34',
      city: 'Uberlândia',
      uf: 'MG',
    },
    {
      code: '35',
      city: 'Poços de Caldas',
      uf: 'MG',
    },
    {
      code: '37',
      city: 'Divinópolis',
      uf: 'MG',
    },
    {
      code: '38',
      city: 'Montes Claros',
      uf: 'MG',
    },
    {
      code: '41',
      city: 'Curitiba',
      uf: 'PR',
    },
    {
      code: '42',
      city: 'Ponta Grossa',
      uf: 'PR',
    },
    {
      code: '43',
      city: 'Londrina',
      uf: 'PR',
    },
    {
      code: '44',
      city: 'Maringá',
      uf: 'PR',
    },
    {
      code: '45',
      city: 'Foz do Iguaçú',
      uf: 'PR',
    },
    {
      code: '46',
      city: 'Francisco Beltrão/Pato Branco',
      uf: 'PR',
    },
    {
      code: '47',
      city: 'Joinville',
      uf: 'SC',
    },
    {
      code: '48',
      city: 'Florianópolis',
      uf: 'SC',
    },
    {
      code: '49',
      city: 'Chapecó',
      uf: 'SC',
    },
    {
      code: '51',
      city: 'Porto Alegre',
      uf: 'RS',
    },
    {
      code: '53',
      city: 'Pelotas',
      uf: 'RS',
    },
    {
      code: '54',
      city: 'Caxias do Sul',
      uf: 'RS',
    },
    {
      code: '55',
      city: 'Santa Maria',
      uf: 'RS',
    },
    {
      code: '61',
      city: 'Brasília',
      uf: 'DF',
    },
    {
      code: '62',
      city: 'Goiânia',
      uf: 'GO',
    },
    {
      code: '63',
      city: 'Palmas',
      uf: 'TO',
    },
    {
      code: '64',
      city: 'Rio Verde',
      uf: 'GO',
    },
    {
      code: '65',
      city: 'Cuiabá',
      uf: 'MT',
    },
    {
      code: '66',
      city: 'Rondonópolis',
      uf: 'MT',
    },
    {
      code: '67',
      city: 'Campo Grande',
      uf: 'MS',
    },
    {
      code: '68',
      city: 'Rio Branco',
      uf: 'AC',
    },
    {
      code: '69',
      city: 'Porto Velho',
      uf: 'RO',
    },
    {
      code: '71',
      city: 'Salvador',
      uf: 'BA',
    },
    {
      code: '73',
      city: 'Ilhéus',
      uf: 'BA',
    },
    {
      code: '74',
      city: 'Juazeiro',
      uf: 'BA',
    },
    {
      code: '75',
      city: 'Feira de Santana',
      uf: 'BA',
    },
    {
      code: '77',
      city: 'Barreiras',
      uf: 'BA',
    },
    {
      code: '79',
      city: 'Aracaju',
      uf: 'SE',
    },
    {
      code: '81',
      city: 'Recife',
      uf: 'PE',
    },
    {
      code: '82',
      city: 'Maceió',
      uf: 'AL',
    },
    {
      code: '83',
      city: 'João Pessoa',
      uf: 'PB',
    },
    {
      code: '84',
      city: 'Natal',
      uf: 'RN',
    },
    {
      code: '85',
      city: 'Fortaleza',
      uf: 'CE',
    },
    {
      code: '86',
      city: 'Teresina',
      uf: 'PI',
    },
    {
      code: '87',
      city: 'Petrolina',
      uf: 'PE',
    },
    {
      code: '88',
      city: 'Juazeiro do Norte',
      uf: 'CE',
    },
    {
      code: '89',
      city: 'Picos',
      uf: 'PI',
    },
    {
      code: '91',
      city: 'Belém',
      uf: 'PA',
    },
    {
      code: '92',
      city: 'Manaus',
      uf: 'AM',
    },
    {
      code: '93',
      city: 'Santarém',
      uf: 'PA',
    },
    {
      code: '94',
      city: 'Marabá',
      uf: 'PA',
    },
    {
      code: '95',
      city: 'Boa Vista',
      uf: 'RR',
    },
    {
      code: '96',
      city: 'Macapá',
      uf: 'AP',
    },
    {
      code: '97',
      city: 'Coari',
      uf: 'AM',
    },
    {
      code: '98',
      city: 'São Luís',
      uf: 'MA',
    },
    {
      code: '99',
      city: 'Imperatriz',
      uf: 'MA',
    },
  ] as const
