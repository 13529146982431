import abcLogoPath from './images/logo-abc.svg'
import abcLogoNegativePath from './images/logo-abc-white.svg'
import colors from '../../colors'

export const ABC = {
  tenant: {
    title: 'Banco ABC Brasil',
    name: 'Banco ABC Brasil',
    favicon: 'favicon-abc.png',
    privacyPolicyUrl: 'https://www.abcbrasil.com.br/politica-de-privacidade/',
    logo: {
      new: abcLogoPath,
      main: abcLogoPath,
      negative: abcLogoPath,
      white: abcLogoNegativePath,
      short: abcLogoPath,
      error: abcLogoNegativePath,
    },
  },
  palette: {
    primary: {
      main: '#A58154',
      dark: '#634D32',
      light: '#E4D9CB',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#000',
      light: '#000',
      contrastText: '#666',
      dark: '#000',
    },
    error: {
      main: '#F2443E',
    },
    warning: {
      main: '#FFC542',
    },
    success: {
      main: '#32C069',
    },
    colors,
  },
}
