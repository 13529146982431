import { ABC as abc } from './profiles/abc'
import { PAKETA as paketa } from './profiles/paketa'
import { SAHCRED as sahcred } from './profiles/sahcred'
import { DEFAULT as defaultTheme } from './profiles/default'

export const THEMES: { [key: string]: typeof defaultTheme } = {
  abc,
  paketa,
  sahcred,
  default: defaultTheme,
}
