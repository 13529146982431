import logoSAHCRED from './images/logo-sahcred.svg'
import logoSAHCREDWhite from './images/logo-sahcred-white.svg'
import logoSAHCREDShort from './images/logo-sahcred-short.svg'
import colors from '../../colors'

export const SAHCRED = {
  tenant: {
    title: 'Sah Cred',
    name: 'Sah Cred',
    favicon: 'favicon-sahcred.png',
    logo: {
      new: logoSAHCRED,
      main: logoSAHCRED,
      negative: logoSAHCREDWhite,
      white: logoSAHCREDWhite,
      short: logoSAHCREDShort,
      error: logoSAHCRED,
    },
  },
  palette: {
    primary: {
      main: '#0D2D4F',
      dark: '#0D2D4F',
      light: '#0D2D4F',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#950A0D',
      light: '#950A0D', 
      contrastText: '#FFF',
      dark: '#950A0D'    
    },
    error: {
      main: '#F2443E',
    },
    warning: {
      main: '#FFC542',
    },
    success: {
      main: '#32C069',
    },
    colors,
  },
}
