import emptyLogo from './images/emptyLogo.svg'
import colors from '../../colors'

export const DEFAULT = {
  tenant: {
    title: 'Empresa - Empréstimo 100% online | Simule agora!',
    name: 'Empresa',
    favicon: 'favicon-empresa.png',
    logo: {
      new: emptyLogo,
      main: emptyLogo,
      negative: emptyLogo,
      white: emptyLogo,
      short: emptyLogo,
      error: emptyLogo,
    },
  },
  palette: {
    primary: {
      main: '#CE4871',
      dark: '#A62652',
      light: '#DF8FA9',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#44444F',
      light: '#92DDF3',
      contrastText: '#FFF',
      dark: '#192145',
    },
    error: {
      main: '#FC5A5A',
    },
    warning: {
      main: '#FFC542',
    },
    success: {
      main: '#00B894',
    },
    colors,
  },
}
