const envs = ['', 'dev.', 'hml.', 'uat.']

const configurations = {
  abc: {
    organization: 'REACT_APP_AUTH0_ORGANIZATION_ABC',
    connect: 'abc'
  },
  paketa: {
    organization: 'REACT_APP_AUTH0_ORGANIZATION_PAKETA',
    connection: 'paketa'
  },
  sahcred: {
    organization: 'REACT_APP_AUTH0_ORGANIZATION_SAHCRED',
    connection: 'sahcred'
  }
}

const hostnames = {
  abc: [
    ...[
      'contrateaqui.abcbrasil.com.br',
      'cockpit.contrateaqui.abcbrasil.com.br',
      'bko.contrateaqui.abcbrasil.com.br'
    ],
    ...envs.map((env) => `abc.${env}base39.io`),
    ...envs.map((env) => `abc.cockpit.${env}base39.io`),
    ...envs.map((env) => `abc.backoffice.${env}base39.io`)
  ],
  paketa: [
    ...[`consignado.paketa.com.br`, `cockpit.paketa.com.br`, `bko.paketa.com.br`],
    ...envs.map((env) => `paketa.${env}base39.io`),
    ...envs.map((env) => `paketa.cockpit.${env}base39.io`),
    ...envs.map((env) => `paketa.backoffice.${env}base39.io`),
    'localhost'
  ],
  sahcred: [
    ...['sahcred.base39.io', 'sahcred.cockpit.base39.io', 'sahcred.backoffice.base39.io']
  ],
  tenant1: [
    ...envs.map((env) => `tenant1.${env}base39.io`),
    ...envs.map((env) => `tenant1.cockpit.${env}base39.io`),
    ...envs.map((env) => `tenant1.backoffice.${env}base39.io`)
  ],
  tenant2: [
    ...envs.map((env) => `tenant2.${env}base39.io`),
    ...envs.map((env) => `tenant2.cockpit.${env}base39.io`),
    ...envs.map((env) => `tenant2.backoffice.${env}base39.io`)
  ]
}

export const getTenant = () => {
  const hostname = window.location.hostname
  let tenant

  Object.keys(hostnames).forEach((key) => {
    if (hostnames[key].find((data) => data === hostname)) {
      tenant = key
    }
  })

  return tenant
}

export const getConfiguration = () => configurations[getTenant()] || {}

export default {
  getConfiguration,
  getTenant
}
