export const BANKS = [
  {
    value: "033",
    label: "BANCO SANTANDER (BRASIL) S.A.",
    shortName: "BCO SANTANDER (BRASIL) S.A."
  },
  {
    value: "341",
    label: "ITAÚ UNIBANCO S.A.",
    shortName: "ITAÚ UNIBANCO S.A."
  },
  {
    value: "104",
    label: "Caixa Econômica Federal",
    shortName: "CAIXA ECONOMICA FEDERAL"
  },
  {
    value: "237",
    label: "Banco Bradesco S.A.",
    shortName: "BCO BRADESCO S.A."
  },
  {
    value: "260",
    label: "Nu Pagamentos S.A.",
    shortName: "NU PAGAMENTOS S.A."
  },
  {
    value: "001",
    label: "Banco do Brasil S.A.",
    shortName: "BCO DO BRASIL S.A."
  },
  {
    value: "003",
    label: "Banco da Amazônia S.A.",
    shortName: "BCO DA AMAZONIA S.A."
  },
  {
    value: "004",
    label: "Banco do Nordeste do Brasil S.A.",
    shortName: "BCO DO NORDESTE DO BRASIL S.A."
  },
  {
    value: "007",
    label: "BANCO NACIONAL DE DESENVOLVIMENTO ECONOMICO E SOCIAL",
    shortName: "BNDES"
  },
  {
    value: "010",
    label: "CREDICOAMO CREDITO RURAL COOPERATIVA",
    shortName: "CREDICOAMO"
  },
  {
    value: "011",
    label: "CREDIT SUISSE HEDGING-GRIFFO CORRETORA DE VALORES S.A",
    shortName: "C.SUISSE HEDGING-GRIFFO CV S/A"
  },
  {
    value: "012",
    label: "Banco Inbursa S.A.",
    shortName: "BANCO INBURSA"
  },
  {
    value: "014",
    label: "STATE STREET BRASIL S.A. - BANCO COMERCIAL",
    shortName: "STATE STREET BR S.A. BCO COMERCIAL"
  },
  {
    value: "015",
    label: "UBS Brasil Corretora de Câmbio, Títulos e Valores Mobiliários S.A.",
    shortName: "UBS BRASIL CCTVM S.A."
  },
  {
    value: "016",
    label: "COOPERATIVA DE CRÉDITO MÚTUO DOS DESPACHANTES DE TRÂNSITO DE SANTA CATARINA E RI",
    shortName: "CCM DESP TRÂNS SC E RS"
  },
  {
    value: "017",
    label: "BNY Mellon Banco S.A.",
    shortName: "BNY MELLON BCO S.A."
  },
  {
    value: "018",
    label: "Banco Tricury S.A.",
    shortName: "BCO TRICURY S.A."
  },
  {
    value: "021",
    label: "BANESTES S.A. Banco do Estado do Espírito Santo",
    shortName: "BCO BANESTES S.A."
  },
  {
    value: "024",
    label: "Banco Bandepe S.A.",
    shortName: "BCO BANDEPE S.A."
  },
  {
    value: "025",
    label: "Banco Alfa S.A.",
    shortName: "BCO ALFA S.A."
  },
  {
    value: "029",
    label: "Banco Itaú Consignado S.A.",
    shortName: "BANCO ITAÚ CONSIGNADO S.A."
  },
  {
    value: "036",
    label: "Banco Bradesco BBI S.A.",
    shortName: "BCO BBI S.A."
  },
  {
    value: "037",
    label: "Banco do Estado do Pará S.A.",
    shortName: "BCO DO EST. DO PA S.A."
  },
  {
    value: "040",
    label: "Banco Cargill S.A.",
    shortName: "BCO CARGILL S.A."
  },
  {
    value: "041",
    label: "Banco do Estado do Rio Grande do Sul S.A.",
    shortName: "BCO DO ESTADO DO RS S.A."
  },
  {
    value: "047",
    label: "Banco do Estado de Sergipe S.A.",
    shortName: "BCO DO EST. DE SE S.A."
  },
  {
    value: "060",
    label: "Confidence Corretora de Câmbio S.A.",
    shortName: "CONFIDENCE CC S.A."
  },
  {
    value: "062",
    label: "Hipercard Banco Múltiplo S.A.",
    shortName: "HIPERCARD BM S.A."
  },
  {
    value: "063",
    label: "Banco Bradescard S.A.",
    shortName: "BANCO BRADESCARD"
  },
  {
    value: "064",
    label: "Goldman Sachs do Brasil Banco Múltiplo S.A.",
    shortName: "GOLDMAN SACHS DO BRASIL BM S.A"
  },
  {
    value: "065",
    label: "Banco AndBank (Brasil) S.A.",
    shortName: "BCO ANDBANK S.A."
  },
  {
    value: "066",
    label: "BANCO MORGAN STANLEY S.A.",
    shortName: "BCO MORGAN STANLEY S.A."
  },
  {
    value: "069",
    label: "Banco Crefisa S.A.",
    shortName: "BCO CREFISA S.A."
  },
  {
    value: "070",
    label: "BRB - Banco de Brasília S.A.",
    shortName: "BRB - BCO DE BRASILIA S.A."
  },
  {
    value: "074",
    label: "Banco J. Safra S.A.",
    shortName: "BCO. J.SAFRA S.A."
  },
  {
    value: "075",
    label: "Banco ABN Amro S.A.",
    shortName: "BCO ABN AMRO S.A."
  },
  {
    value: "076",
    label: "Banco KDB do Brasil S.A.",
    shortName: "BCO KDB BRASIL S.A."
  },
  {
    value: "077",
    label: "Banco Inter S.A.",
    shortName: "BANCO INTER"
  },
  {
    value: "078",
    label: "Haitong Banco de Investimento do Brasil S.A.",
    shortName: "HAITONG BI DO BRASIL S.A."
  },
  {
    value: "079",
    label: "Banco Original do Agronegócio S.A.",
    shortName: "BCO ORIGINAL DO AGRO S/A"
  },
  {
    value: "080",
    label: "B&T CORRETORA DE CAMBIO LTDA.",
    shortName: "B&T CC LTDA."
  },
  {
    value: "081",
    label: "BancoSeguro S.A.",
    shortName: "BANCOSEGURO S.A."
  },
  {
    value: "082",
    label: "BANCO TOPÁZIO S.A.",
    shortName: "BANCO TOPÁZIO S.A."
  },
  {
    value: "083",
    label: "Banco da China Brasil S.A.",
    shortName: "BCO DA CHINA BRASIL S.A."
  },
  {
    value: "084",
    label: "UNIPRIME NORTE DO PARANÁ - COOPERATIVA DE CRÉDITO LTDA",
    shortName: "UNIPRIME NORTE DO PARANÁ - CC"
  },
  {
    value: "085",
    label: "Cooperativa Central de Crédito - Ailos",
    shortName: "COOPCENTRAL AILOS"
  },
  {
    value: "088",
    label: "BANCO RANDON S.A.",
    shortName: "BANCO RANDON S.A."
  },
  {
    value: "089",
    label: "CREDISAN COOPERATIVA DE CRÉDITO",
    shortName: "CREDISAN CC"
  },
  {
    value: "091",
    label: "CENTRAL DE COOPERATIVAS DE ECONOMIA E CRÉDITO MÚTUO DO ESTADO DO RIO GRANDE DO S",
    shortName: "CCCM UNICRED CENTRAL RS"
  },
  {
    value: "092",
    label: "BRK S.A. Crédito, Financiamento e Investimento",
    shortName: "BRK S.A. CFI"
  },
  {
    value: "093",
    label: "PÓLOCRED   SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORT",
    shortName: "POLOCRED SCMEPP LTDA."
  },
  {
    value: "094",
    label: "Banco Finaxis S.A.",
    shortName: "BANCO FINAXIS"
  },
  {
    value: "095",
    label: "Travelex Banco de Câmbio S.A.",
    shortName: "TRAVELEX BANCO DE CÂMBIO S.A."
  },
  {
    value: "096",
    label: "Banco B3 S.A.",
    shortName: "BCO B3 S.A."
  },
  {
    value: "097",
    label: "Credisis - Central de Cooperativas de Crédito Ltda.",
    shortName: "CREDISIS CENTRAL DE COOPERATIVAS DE CRÉDITO LTDA."
  },
  {
    value: "098",
    label: "Credialiança Cooperativa de Crédito Rural",
    shortName: "CREDIALIANÇA CCR"
  },
  {
    value: "099",
    label: "UNIPRIME CENTRAL - CENTRAL INTERESTADUAL DE COOPERATIVAS DE CREDITO LTDA.",
    shortName: "UNIPRIME CENTRAL CCC LTDA."
  },
  {
    value: "100",
    label: "Planner Corretora de Valores S.A.",
    shortName: "PLANNER CV S.A."
  },
  {
    value: "101",
    label: "RENASCENCA DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA",
    shortName: "RENASCENCA DTVM LTDA"
  },
  {
    value: "102",
    label: "XP INVESTIMENTOS CORRETORA DE CÂMBIO,TÍTULOS E VALORES MOBILIÁRIOS S/A",
    shortName: "XP INVESTIMENTOS CCTVM S/A"
  },
  {
    value: "105",
    label: "Lecca Crédito, Financiamento e Investimento S/A",
    shortName: "LECCA CFI S.A."
  },
  {
    value: "107",
    label: "Banco Bocom BBM S.A.",
    shortName: "BCO BOCOM BBM S.A."
  },
  {
    value: "108",
    label: "PORTOCRED S.A. - CREDITO, FINANCIAMENTO E INVESTIMENTO",
    shortName: "PORTOCRED S.A. - CFI"
  },
  {
    value: "111",
    label: "OLIVEIRA TRUST DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIARIOS S.A.",
    shortName: "OLIVEIRA TRUST DTVM S.A."
  },
  {
    value: "113",
    label: "MAGLIANO S.A. CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS",
    shortName: "MAGLIANO"
  },
  {
    value: "114",
    label: "Central Cooperativa de Crédito no Estado do Espírito Santo - CECOOP",
    shortName: "CENTRAL COOPERATIVA DE CRÉDITO NO ESTADO DO ESPÍRITO SANTO"
  },
  {
    value: "117",
    label: "ADVANCED CORRETORA DE CÂMBIO LTDA",
    shortName: "ADVANCED CC LTDA"
  },
  {
    value: "119",
    label: "Banco Western Union do Brasil S.A.",
    shortName: "BCO WESTERN UNION"
  },
  {
    value: "120",
    label: "BANCO RODOBENS S.A.",
    shortName: "BCO RODOBENS S.A."
  },
  {
    value: "121",
    label: "Banco Agibank S.A.",
    shortName: "BCO AGIBANK S.A."
  },
  {
    value: "122",
    label: "Banco Bradesco BERJ S.A.",
    shortName: "BCO BRADESCO BERJ S.A."
  },
  {
    value: "124",
    label: "Banco Woori Bank do Brasil S.A.",
    shortName: "BCO WOORI BANK DO BRASIL S.A."
  },
  {
    value: "125",
    label: "BANCO GENIAL S.A.",
    shortName: "BANCO GENIAL"
  },
  {
    value: "126",
    label: "BR Partners Banco de Investimento S.A.",
    shortName: "BR PARTNERS BI"
  },
  {
    value: "127",
    label: "Codepe Corretora de Valores e Câmbio S.A.",
    shortName: "CODEPE CVC S.A."
  },
  {
    value: "128",
    label: "MS Bank S.A. Banco de Câmbio",
    shortName: "MS BANK S.A. BCO DE CÂMBIO"
  },
  {
    value: "129",
    label: "UBS Brasil Banco de Investimento S.A.",
    shortName: "UBS BRASIL BI S.A."
  },
  {
    value: "130",
    label: "CARUANA S.A. - SOCIEDADE DE CRÉDITO, FINANCIAMENTO E INVESTIMENTO",
    shortName: "CARUANA SCFI"
  },
  {
    value: "131",
    label: "TULLETT PREBON BRASIL CORRETORA DE VALORES E CÂMBIO LTDA",
    shortName: "TULLETT PREBON BRASIL CVC LTDA"
  },
  {
    value: "132",
    label: "ICBC do Brasil Banco Múltiplo S.A.",
    shortName: "ICBC DO BRASIL BM S.A."
  },
  {
    value: "133",
    label: "CONFEDERAÇÃO NACIONAL DAS COOPERATIVAS CENTRAIS DE CRÉDITO E ECONOMIA FAMILIAR E",
    shortName: "CRESOL CONFEDERAÇÃO"
  },
  {
    value: "134",
    label: "BGC LIQUIDEZ DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA",
    shortName: "BGC LIQUIDEZ DTVM LTDA"
  },
  {
    value: "136",
    label: "CONFEDERAÇÃO NACIONAL DAS COOPERATIVAS CENTRAIS UNICRED LTDA. - UNICRED DO BRASI",
    shortName: "CONF NAC COOP CENTRAIS UNICRED"
  },
  {
    value: "138",
    label: "Get Money Corretora de Câmbio S.A.",
    shortName: "GET MONEY CC LTDA"
  },
  {
    value: "139",
    label: "Intesa Sanpaolo Brasil S.A. - Banco Múltiplo",
    shortName: "INTESA SANPAOLO BRASIL S.A. BM"
  },
  {
    value: "140",
    label: "Easynvest - Título Corretora de Valores SA",
    shortName: "EASYNVEST - TÍTULO CV SA"
  },
  {
    value: "142",
    label: "Broker Brasil Corretora de Câmbio Ltda.",
    shortName: "BROKER BRASIL CC LTDA."
  },
  {
    value: "143",
    label: "Treviso Corretora de Câmbio S.A.",
    shortName: "TREVISO CC S.A."
  },
  {
    value: "144",
    label: "BEXS BANCO DE CÂMBIO S/A",
    shortName: "BEXS BCO DE CAMBIO S.A."
  },
  {
    value: "145",
    label: "LEVYCAM - CORRETORA DE CAMBIO E VALORES LTDA.",
    shortName: "LEVYCAM CCV LTDA"
  },
  {
    value: "146",
    label: "GUITTA CORRETORA DE CAMBIO LTDA.",
    shortName: "GUITTA CC LTDA"
  },
  {
    value: "149",
    label: "Facta Financeira S.A. - Crédito Financiamento e Investimento",
    shortName: "FACTA S.A. CFI"
  },
  {
    value: "157",
    label: "ICAP do Brasil Corretora de Títulos e Valores Mobiliários Ltda.",
    shortName: "ICAP DO BRASIL CTVM LTDA."
  },
  {
    value: "159",
    label: "Casa do Crédito S.A. Sociedade de Crédito ao Microempreendedor",
    shortName: "CASA CREDITO S.A. SCM"
  },
  {
    value: "163",
    label: "Commerzbank Brasil S.A. - Banco Múltiplo",
    shortName: "COMMERZBANK BRASIL S.A. - BCO MÚLTIPLO"
  },
  {
    value: "173",
    label: "BRL Trust Distribuidora de Títulos e Valores Mobiliários S.A.",
    shortName: "BRL TRUST DTVM SA"
  },
  {
    value: "174",
    label: "PEFISA S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO",
    shortName: "PEFISA S.A. - CFI"
  },
  {
    value: "177",
    label: "Guide Investimentos S.A. Corretora de Valores",
    shortName: "GUIDE"
  },
  {
    value: "180",
    label: "CM CAPITAL MARKETS CORRETORA DE CÂMBIO, TÍTULOS E VALORES MOBILIÁRIOS LTDA",
    shortName: "CM CAPITAL MARKETS CCTVM LTDA"
  },
  {
    value: "183",
    label: "SOCRED S.A. - SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO P",
    shortName: "SOCRED SA - SCMEPP"
  },
  {
    value: "184",
    label: "Banco Itaú BBA S.A.",
    shortName: "BCO ITAÚ BBA S.A."
  },
  {
    value: "188",
    label: "ATIVA INVESTIMENTOS S.A. CORRETORA DE TÍTULOS, CÂMBIO E VALORES",
    shortName: "ATIVA S.A. INVESTIMENTOS CCTVM"
  },
  {
    value: "189",
    label: "HS FINANCEIRA S/A CREDITO, FINANCIAMENTO E INVESTIMENTOS",
    shortName: "HS FINANCEIRA"
  },
  {
    value: "190",
    label: "SERVICOOP - COOPERATIVA DE CRÉDITO DOS SERVIDORES PÚBLICOS ESTADUAIS DO RIO GRAN",
    shortName: "SERVICOOP"
  },
  {
    value: "191",
    label: "Nova Futura Corretora de Títulos e Valores Mobiliários Ltda.",
    shortName: "NOVA FUTURA CTVM LTDA."
  },
  {
    value: "194",
    label: "PARMETAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA",
    shortName: "PARMETAL DTVM LTDA"
  },
  {
    value: "196",
    label: "FAIR CORRETORA DE CAMBIO S.A.",
    shortName: "FAIR CC S.A."
  },
  {
    value: "197",
    label: "Stone Pagamentos S.A.",
    shortName: "STONE PAGAMENTOS S.A."
  },
  {
    value: "208",
    label: "Banco BTG Pactual S.A.",
    shortName: "BANCO BTG PACTUAL S.A."
  },
  {
    value: "212",
    label: "Banco Original S.A.",
    shortName: "BANCO ORIGINAL"
  },
  {
    value: "213",
    label: "Banco Arbi S.A.",
    shortName: "BCO ARBI S.A."
  },
  {
    value: "217",
    label: "Banco John Deere S.A.",
    shortName: "BANCO JOHN DEERE S.A."
  },
  {
    value: "218",
    label: "Banco BS2 S.A.",
    shortName: "BCO BS2 S.A."
  },
  {
    value: "222",
    label: "Banco Credit Agricole Brasil S.A.",
    shortName: "BCO CRÉDIT AGRICOLE BR S.A."
  },
  {
    value: "224",
    label: "Banco Fibra S.A.",
    shortName: "BCO FIBRA S.A."
  },
  {
    value: "233",
    label: "Banco Cifra S.A.",
    shortName: "BANCO CIFRA"
  },
  {
    value: "241",
    label: "BANCO CLASSICO S.A.",
    shortName: "BCO CLASSICO S.A."
  },
  {
    value: "243",
    label: "BANCO MASTER S/A",
    shortName: "BANCO MASTER"
  },
  {
    value: "246",
    label: "Banco ABC Brasil S.A.",
    shortName: "BCO ABC BRASIL S.A."
  },
  {
    value: "249",
    label: "Banco Investcred Unibanco S.A.",
    shortName: "BANCO INVESTCRED UNIBANCO S.A."
  },
  {
    value: "250",
    label: "BCV - BANCO DE CRÉDITO E VAREJO S.A.",
    shortName: "\"BCV - BCO, CRÉDITO E VAREJO S.A.                            \""
  },
  {
    value: "253",
    label: "Bexs Corretora de Câmbio S/A",
    shortName: "BEXS CC S.A."
  },
  {
    value: "254",
    label: "PARANÁ BANCO S.A.",
    shortName: "PARANA BCO S.A."
  },
  {
    value: "259",
    label: "MONEYCORP BANCO DE CÂMBIO S.A.",
    shortName: "MONEYCORP BCO DE CÂMBIO S.A."
  },
  {
    value: "265",
    label: "Banco Fator S.A.",
    shortName: "BCO FATOR S.A."
  },
  {
    value: "266",
    label: "BANCO CEDULA S.A.",
    shortName: "BCO CEDULA S.A."
  },
  {
    value: "268",
    label: "BARI COMPANHIA HIPOTECÁRIA",
    shortName: "BARI CIA HIPOTECÁRIA"
  },
  {
    value: "269",
    label: "BANCO HSBC S.A.",
    shortName: "BCO HSBC S.A."
  },
  {
    value: "270",
    label: "Sagitur Corretora de Câmbio Ltda.",
    shortName: "SAGITUR CC LTDA"
  },
  {
    value: "271",
    label: "IB Corretora de Câmbio, Títulos e Valores Mobiliários S.A.",
    shortName: "IB CCTVM S.A."
  },
  {
    value: "272",
    label: "AGK CORRETORA DE CAMBIO S.A.",
    shortName: "AGK CC S.A."
  },
  {
    value: "273",
    label: "Cooperativa de Crédito Rural de São Miguel do Oeste - Sulcredi/São Miguel",
    shortName: "CCR DE SÃO MIGUEL DO OESTE"
  },
  {
    value: "274",
    label: "MONEY PLUS SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORT",
    shortName: "MONEY PLUS SCMEPP LTDA"
  },
  {
    value: "276",
    label: "BANCO SENFF S.A.",
    shortName: "BCO SENFF S.A."
  },
  {
    value: "278",
    label: "Genial Investimentos Corretora de Valores Mobiliários S.A.",
    shortName: "GENIAL INVESTIMENTOS CVM S.A."
  },
  {
    value: "279",
    label: "COOPERATIVA DE CREDITO RURAL DE PRIMAVERA DO LESTE",
    shortName: "CCR DE PRIMAVERA DO LESTE"
  },
  {
    value: "280",
    label: "WILL FINANCEIRA S.A. CRÉDITO, FINANCIAMENTO E INVESTIMENTO",
    shortName: "WILL FINANCEIRA S.A.CFI"
  },
  {
    value: "281",
    label: "Cooperativa de Crédito Rural Coopavel",
    shortName: "CCR COOPAVEL"
  },
  {
    value: "283",
    label: "RB INVESTIMENTOS DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LIMITADA",
    shortName: "RB INVESTIMENTOS DTVM LTDA."
  },
  {
    value: "285",
    label: "Frente Corretora de Câmbio Ltda.",
    shortName: "FRENTE CC LTDA."
  },
  {
    value: "286",
    label: "COOPERATIVA DE CRÉDITO RURAL DE OURO   SULCREDI/OURO",
    shortName: "CCR DE OURO"
  },
  {
    value: "288",
    label: "CAROL DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.",
    shortName: "CAROL DTVM LTDA."
  },
  {
    value: "289",
    label: "DECYSEO CORRETORA DE CAMBIO LTDA.",
    shortName: "DECYSEO CC LTDA."
  },
  {
    value: "290",
    label: "Pagseguro Internet S.A.",
    shortName: "PAGSEGURO S.A."
  },
  {
    value: "292",
    label: "BS2 Distribuidora de Títulos e Valores Mobiliários S.A.",
    shortName: "BS2 DTVM S.A."
  },
  {
    value: "293",
    label: "Lastro RDV Distribuidora de Títulos e Valores Mobiliários Ltda.",
    shortName: "LASTRO RDV DTVM LTDA"
  },
  {
    value: "296",
    label: "VISION S.A. CORRETORA DE CAMBIO",
    shortName: "VISION S.A. CC"
  },
  {
    value: "298",
    label: "Vip's Corretora de Câmbio Ltda.",
    shortName: "VIPS CC LTDA."
  },
  {
    value: "299",
    label: "BANCO SOROCRED S.A. - BANCO MÚLTIPLO",
    shortName: "BCO SOROCRED S.A. - BM"
  },
  {
    value: "300",
    label: "Banco de la Nacion Argentina",
    shortName: "BCO LA NACION ARGENTINA"
  },
  {
    value: "301",
    label: "BPP Instituição de Pagamento S.A.",
    shortName: "BPP IP S.A."
  },
  {
    value: "306",
    label: "PORTOPAR DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.",
    shortName: "PORTOPAR DTVM LTDA"
  },
  {
    value: "307",
    label: "Terra Investimentos Distribuidora de Títulos e Valores Mobiliários Ltda.",
    shortName: "TERRA INVESTIMENTOS DTVM"
  },
  {
    value: "309",
    label: "CAMBIONET CORRETORA DE CÂMBIO LTDA.",
    shortName: "CAMBIONET CC LTDA"
  },
  {
    value: "310",
    label: "VORTX DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.",
    shortName: "VORTX DTVM LTDA."
  },
  {
    value: "311",
    label: "DOURADA CORRETORA DE CÂMBIO LTDA.",
    shortName: "DOURADA CORRETORA"
  },
  {
    value: "312",
    label: "HSCM - SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LT",
    shortName: "HSCM SCMEPP LTDA."
  },
  {
    value: "313",
    label: "AMAZÔNIA CORRETORA DE CÂMBIO LTDA.",
    shortName: "AMAZÔNIA CC LTDA."
  },
  {
    value: "315",
    label: "PI Distribuidora de Títulos e Valores Mobiliários S.A.",
    shortName: "PI DTVM S.A."
  },
  {
    value: "318",
    label: "Banco BMG S.A.",
    shortName: "BCO BMG S.A."
  },
  {
    value: "319",
    label: "OM DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA",
    shortName: "OM DTVM LTDA"
  },
  {
    value: "320",
    label: "China Construction Bank (Brasil) Banco Múltiplo S/A",
    shortName: "BCO CCB BRASIL S.A."
  },
  {
    value: "321",
    label: "CREFAZ SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORTE LT",
    shortName: "CREFAZ SCMEPP LTDA"
  },
  {
    value: "322",
    label: "Cooperativa de Crédito Rural de Abelardo Luz - Sulcredi/Crediluz",
    shortName: "CCR DE ABELARDO LUZ"
  },
  {
    value: "323",
    label: "MERCADOPAGO.COM REPRESENTACOES LTDA.",
    shortName: "MERCADO PAGO"
  },
  {
    value: "324",
    label: "CARTOS SOCIEDADE DE CRÉDITO DIRETO S.A.",
    shortName: "CARTOS SCD S.A."
  },
  {
    value: "325",
    label: "Órama Distribuidora de Títulos e Valores Mobiliários S.A.",
    shortName: "ÓRAMA DTVM S.A."
  },
  {
    value: "326",
    label: "PARATI - CREDITO, FINANCIAMENTO E INVESTIMENTO S.A.",
    shortName: "PARATI - CFI S.A."
  },
  {
    value: "328",
    label: "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS FABRICANTES DE CALÇADOS DE SAPIRANGA",
    shortName: "CECM FABRIC CALÇADOS SAPIRANGA"
  },
  {
    value: "329",
    label: "QI Sociedade de Crédito Direto S.A.",
    shortName: "QI SCD S.A."
  },
  {
    value: "330",
    label: "BANCO BARI DE INVESTIMENTOS E FINANCIAMENTOS S.A.",
    shortName: "BANCO BARI S.A."
  },
  {
    value: "331",
    label: "Fram Capital Distribuidora de Títulos e Valores Mobiliários S.A.",
    shortName: "FRAM CAPITAL DTVM S.A."
  },
  {
    value: "332",
    label: "Acesso Soluções de Pagamento S.A.",
    shortName: "ACESSO SOLUCOES PAGAMENTO SA"
  },
  {
    value: "335",
    label: "Banco Digio S.A.",
    shortName: "BANCO DIGIO"
  },
  {
    value: "336",
    label: "Banco C6 S.A.",
    shortName: "BCO C6 S.A."
  },
  {
    value: "340",
    label: "Super Pagamentos e Administração de Meios Eletrônicos S.A.",
    shortName: "SUPER PAGAMENTOS E ADMINISTRACAO DE MEIOS ELETRONICOS S.A."
  },
  {
    value: "342",
    label: "Creditas Sociedade de Crédito Direto S.A.",
    shortName: "CREDITAS SCD"
  },
  {
    value: "343",
    label: "FFA SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LTDA.",
    shortName: "FFA SCMEPP LTDA."
  },
  {
    value: "348",
    label: "Banco XP S.A.",
    shortName: "BCO XP S.A."
  },
  {
    value: "349",
    label: "AL5 S.A. CRÉDITO, FINANCIAMENTO E INVESTIMENTO",
    shortName: "AL5 S.A. CFI"
  },
  {
    value: "350",
    label: "COOPERATIVA DE CRÉDITO RURAL DE PEQUENOS AGRICULTORES E DA REFORMA AGRÁRIA DO CE",
    shortName: "CREHNOR LARANJEIRAS"
  },
  {
    value: "352",
    label: "TORO CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA",
    shortName: "TORO CTVM LTDA"
  },
  {
    value: "354",
    label: "NECTON INVESTIMENTOS  S.A. CORRETORA DE VALORES MOBILIÁRIOS E COMMODITIES",
    shortName: "NECTON INVESTIMENTOS S.A CVM"
  },
  {
    value: "355",
    label: "ÓTIMO SOCIEDADE DE CRÉDITO DIRETO S.A.",
    shortName: "ÓTIMO SCD S.A."
  },
  {
    value: "358",
    label: "MIDWAY S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO",
    shortName: "MIDWAY S.A. - SCFI"
  },
  {
    value: "359",
    label: "ZEMA CRÉDITO, FINANCIAMENTO E INVESTIMENTO S/A",
    shortName: "ZEMA CFI S/A"
  },
  {
    value: "360",
    label: "TRINUS CAPITAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.",
    shortName: "TRINUS CAPITAL DTVM"
  },
  {
    value: "362",
    label: "CIELO S.A.",
    shortName: "CIELO S.A."
  },
  {
    value: "363",
    label: "SINGULARE CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.",
    shortName: "SINGULARE CTVM S.A."
  },
  {
    value: "364",
    label: "GERENCIANET S.A.",
    shortName: "GERENCIANET"
  },
  {
    value: "365",
    label: "SIMPAUL CORRETORA DE CAMBIO E VALORES MOBILIARIOS  S.A.",
    shortName: "SIMPAUL"
  },
  {
    value: "366",
    label: "BANCO SOCIETE GENERALE BRASIL S.A.",
    shortName: "BCO SOCIETE GENERALE BRASIL"
  },
  {
    value: "367",
    label: "VITREO DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.",
    shortName: "VITREO DTVM S.A."
  },
  {
    value: "368",
    label: "Banco CSF S.A.",
    shortName: "BCO CSF S.A."
  },
  {
    value: "370",
    label: "Banco Mizuho do Brasil S.A.",
    shortName: "BCO MIZUHO S.A."
  },
  {
    value: "371",
    label: "WARREN CORRETORA DE VALORES MOBILIÁRIOS E CÂMBIO LTDA.",
    shortName: "WARREN CVMC LTDA"
  },
  {
    value: "373",
    label: "UP.P SOCIEDADE DE EMPRÉSTIMO ENTRE PESSOAS S.A.",
    shortName: "UP.P SEP S.A."
  },
  {
    value: "374",
    label: "REALIZE CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A.",
    shortName: "REALIZE CFI S.A."
  },
  {
    value: "376",
    label: "BANCO J.P. MORGAN S.A.",
    shortName: "BCO J.P. MORGAN S.A."
  },
  {
    value: "377",
    label: "BMS SOCIEDADE DE CRÉDITO DIRETO S.A.",
    shortName: "BMS SCD S.A."
  },
  {
    value: "378",
    label: "BBC LEASING S.A. - ARRENDAMENTO MERCANTIL",
    shortName: "BBC LEASING"
  },
  {
    value: "379",
    label: "COOPERFORTE - COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DE FUNCIONÁRIOS DE INSTITU",
    shortName: "CECM COOPERFORTE"
  },
  {
    value: "380",
    label: "PICPAY SERVICOS S.A.",
    shortName: "PICPAY"
  },
  {
    value: "381",
    label: "BANCO MERCEDES-BENZ DO BRASIL S.A.",
    shortName: "BCO MERCEDES-BENZ S.A."
  },
  {
    value: "382",
    label: "FIDÚCIA SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE L",
    shortName: "FIDUCIA SCMEPP LTDA"
  },
  {
    value: "383",
    label: "BOLETOBANCÁRIO.COM TECNOLOGIA DE PAGAMENTOS LTDA.",
    shortName: "JUNO"
  },
  {
    value: "384",
    label: "GLOBAL FINANÇAS SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO",
    shortName: "GLOBAL SCM LTDA"
  },
  {
    value: "386",
    label: "NU FINANCEIRA S.A. - Sociedade de Crédito, Financiamento e Investimento",
    shortName: "NU FINANCEIRA S.A. CFI"
  },
  {
    value: "387",
    label: "Banco Toyota do Brasil S.A.",
    shortName: "BCO TOYOTA DO BRASIL S.A."
  },
  {
    value: "389",
    label: "Banco Mercantil do Brasil S.A.",
    shortName: "BCO MERCANTIL DO BRASIL S.A."
  },
  {
    value: "390",
    label: "BANCO GM S.A.",
    shortName: "BCO GM S.A."
  },
  {
    value: "391",
    label: "COOPERATIVA DE CREDITO RURAL DE IBIAM - SULCREDI/IBIAM",
    shortName: "CCR DE IBIAM"
  },
  {
    value: "393",
    label: "Banco Volkswagen S.A.",
    shortName: "BCO VOLKSWAGEN S.A"
  },
  {
    value: "394",
    label: "Banco Bradesco Financiamentos S.A.",
    shortName: "BCO BRADESCO FINANC. S.A."
  },
  {
    value: "395",
    label: "F.D'GOLD - DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.",
    shortName: "F D GOLD DTVM LTDA"
  },
  {
    value: "396",
    label: "HUB PAGAMENTOS S.A",
    shortName: "HUB PAGAMENTOS"
  },
  {
    value: "397",
    label: "LISTO SOCIEDADE DE CREDITO DIRETO S.A.",
    shortName: "LISTO SCD S.A."
  },
  {
    value: "398",
    label: "IDEAL CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.",
    shortName: "IDEAL CTVM S.A."
  },
  {
    value: "399",
    label: "Kirton Bank S.A. - Banco Múltiplo",
    shortName: "KIRTON BANK"
  },
  {
    value: "400",
    label: "COOPERATIVA DE CRÉDITO, POUPANÇA E SERVIÇOS FINANCEIROS DO CENTRO OESTE",
    shortName: "CC POUP SER FIN CO"
  },
  {
    value: "401",
    label: "IUGU INSTITUIÇÃO DE PAGAMENTO S.A.",
    shortName: "IUGU IP S.A."
  },
  {
    value: "402",
    label: "COBUCCIO SOCIEDADE DE CRÉDITO DIRETO S.A.",
    shortName: "COBUCCIO SCD S.A."
  },
  {
    value: "403",
    label: "CORA SOCIEDADE DE CRÉDITO DIRETO S.A.",
    shortName: "CORA SCD S.A."
  },
  {
    value: "404",
    label: "SUMUP SOCIEDADE DE CRÉDITO DIRETO S.A.",
    shortName: "SUMUP SCD S.A."
  },
  {
    value: "406",
    label: "ACCREDITO - SOCIEDADE DE CRÉDITO DIRETO S.A.",
    shortName: "ACCREDITO SCD S.A."
  },
  {
    value: "407",
    label: "ÍNDIGO INVESTIMENTOS DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.",
    shortName: "ÍNDIGO INVESTIMENTOS DTVM LTDA."
  },
  {
    value: "408",
    label: "BONUSPAGO SOCIEDADE DE CRÉDITO DIRETO S.A.",
    shortName: "BONUSPAGO SCD S.A."
  },
  {
    value: "410",
    label: "PLANNER SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR S.A.",
    shortName: "PLANNER SCM S.A."
  },
  {
    value: "411",
    label: "Via Certa Financiadora S.A. - Crédito, Financiamento e Investimentos",
    shortName: "VIA CERTA FINANCIADORA S.A. - CFI"
  },
  {
    value: "412",
    label: "BANCO CAPITAL S.A.",
    shortName: "BCO CAPITAL S.A."
  },
  {
    value: "413",
    label: "BANCO BV S.A.",
    shortName: "BCO BV S.A."
  },
  {
    value: "414",
    label: "WORK SOCIEDADE DE CRÉDITO DIRETO S.A.",
    shortName: "WORK SCD S.A."
  },
  {
    value: "416",
    label: "LAMARA SOCIEDADE DE CRÉDITO DIRETO S.A.",
    shortName: "LAMARA SCD S.A."
  },
  {
    value: "419",
    label: "NUMBRS SOCIEDADE DE CRÉDITO DIRETO S.A.",
    shortName: "NUMBRS SCD S.A."
  },
  {
    value: "421",
    label: "LAR COOPERATIVA DE CRÉDITO - LAR CREDI",
    shortName: "CC LAR CREDI"
  },
  {
    value: "422",
    label: "Banco Safra S.A.",
    shortName: "BCO SAFRA S.A."
  },
  {
    value: "423",
    label: "COLUNA S/A DISTRIBUIDORA DE TITULOS E VALORES MOBILIÁRIOS",
    shortName: "COLUNA S.A. DTVM"
  },
  {
    value: "425",
    label: "SOCINAL S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO",
    shortName: "SOCINAL S.A. CFI"
  },
  {
    value: "426",
    label: "Biorc Financeira - Crédito, Financiamento e Investimento S.A.",
    shortName: "BIORC FINANCEIRA - CFI S.A."
  },
  {
    value: "427",
    label: "COOPERATIVA DE CREDITO DOS SERVIDORES DA UNIVERSIDADE FEDERAL DO ESPIRITO SANTO",
    shortName: "CRED-UFES"
  },
  {
    value: "428",
    label: "CRED-SYSTEM SOCIEDADE DE CRÉDITO DIRETO S.A.",
    shortName: "CRED-SYSTEM SCD S.A."
  },
  {
    value: "433",
    label: "BR-CAPITAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.",
    shortName: "BR-CAPITAL DTVM S.A."
  },
  {
    value: "438",
    label: "PLANNER TRUSTEE DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.",
    shortName: "PLANNER TRUSTEE DTVM LTDA"
  },
  {
    value: "445",
    label: "PLANTAE S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO",
    shortName: "PLANTAE CFI"
  },
  {
    value: "450",
    label: "FITBANK PAGAMENTOS ELETRONICOS S.A.",
    shortName: "FITBANK PAGAMENTOS ELETRONICOS S.A."
  },
  {
    value: "456",
    label: "Banco MUFG Brasil S.A.",
    shortName: "BCO MUFG BRASIL S.A."
  },
  {
    value: "464",
    label: "Banco Sumitomo Mitsui Brasileiro S.A.",
    shortName: "BCO SUMITOMO MITSUI BRASIL S.A."
  },
  {
    value: "473",
    label: "Banco Caixa Geral - Brasil S.A.",
    shortName: "BCO CAIXA GERAL BRASIL S.A."
  },
  {
    value: "477",
    label: "Citibank N.A.",
    shortName: "CITIBANK N.A."
  },
  {
    value: "479",
    label: "Banco ItauBank S.A.",
    shortName: "BCO ITAUBANK S.A."
  },
  {
    value: "487",
    label: "Deutsche Bank S.A. - Banco Alemão",
    shortName: "DEUTSCHE BANK S.A.BCO ALEMAO"
  },
  {
    value: "488",
    label: "JPMorgan Chase Bank, National Association",
    shortName: "JPMORGAN CHASE BANK"
  },
  {
    value: "492",
    label: "ING Bank N.V.",
    shortName: "ING BANK N.V."
  },
  {
    value: "495",
    label: "Banco de La Provincia de Buenos Aires",
    shortName: "BCO LA PROVINCIA B AIRES BCE"
  },
  {
    value: "505",
    label: "Banco Credit Suisse (Brasil) S.A.",
    shortName: "BCO CREDIT SUISSE S.A."
  },
  {
    value: "545",
    label: "SENSO CORRETORA DE CAMBIO E VALORES MOBILIARIOS S.A",
    shortName: "SENSO CCVM S.A."
  },
  {
    value: "600",
    label: "Banco Luso Brasileiro S.A.",
    shortName: "BCO LUSO BRASILEIRO S.A."
  },
  {
    value: "604",
    label: "Banco Industrial do Brasil S.A.",
    shortName: "BCO INDUSTRIAL DO BRASIL S.A."
  },
  {
    value: "610",
    label: "Banco VR S.A.",
    shortName: "BCO VR S.A."
  },
  {
    value: "611",
    label: "Banco Paulista S.A.",
    shortName: "BCO PAULISTA S.A."
  },
  {
    value: "612",
    label: "Banco Guanabara S.A.",
    shortName: "BCO GUANABARA S.A."
  },
  {
    value: "613",
    label: "Omni Banco S.A.",
    shortName: "OMNI BANCO S.A."
  },
  {
    value: "623",
    label: "Banco Pan S.A.",
    shortName: "BANCO PAN"
  },
  {
    value: "626",
    label: "BANCO C6 CONSIGNADO S.A.",
    shortName: "BCO C6 CONSIG"
  },
  {
    value: "630",
    label: "Banco Smartbank S.A.",
    shortName: "SMARTBANK"
  },
  {
    value: "633",
    label: "Banco Rendimento S.A.",
    shortName: "BCO RENDIMENTO S.A."
  },
  {
    value: "634",
    label: "BANCO TRIANGULO S.A.",
    shortName: "BCO TRIANGULO S.A."
  },
  {
    value: "637",
    label: "BANCO SOFISA S.A.",
    shortName: "BCO SOFISA S.A."
  },
  {
    value: "643",
    label: "Banco Pine S.A.",
    shortName: "BCO PINE S.A."
  },
  {
    value: "652",
    label: "Itaú Unibanco Holding S.A.",
    shortName: "ITAÚ UNIBANCO HOLDING S.A."
  },
  {
    value: "653",
    label: "BANCO VOITER S.A.",
    shortName: "BANCO VOITER"
  },
  {
    value: "654",
    label: "BANCO DIGIMAIS S.A.",
    shortName: "BCO DIGIMAIS S.A."
  },
  {
    value: "655",
    label: "Banco Votorantim S.A.",
    shortName: "BCO VOTORANTIM S.A."
  },
  {
    value: "707",
    label: "Banco Daycoval S.A.",
    shortName: "BCO DAYCOVAL S.A"
  },
  {
    value: "712",
    label: "Banco Ourinvest S.A.",
    shortName: "BCO OURINVEST S.A."
  },
  {
    value: "720",
    label: "BANCO RNX S.A.",
    shortName: "BCO RNX S.A."
  },
  {
    value: "739",
    label: "Banco Cetelem S.A.",
    shortName: "BCO CETELEM S.A."
  },
  {
    value: "741",
    label: "BANCO RIBEIRAO PRETO S.A.",
    shortName: "BCO RIBEIRAO PRETO S.A."
  },
  {
    value: "743",
    label: "Banco Semear S.A.",
    shortName: "BANCO SEMEAR"
  },
  {
    value: "745",
    label: "Banco Citibank S.A.",
    shortName: "BCO CITIBANK S.A."
  },
  {
    value: "746",
    label: "Banco Modal S.A.",
    shortName: "BCO MODAL S.A."
  },
  {
    value: "747",
    label: "Banco Rabobank International Brasil S.A.",
    shortName: "BCO RABOBANK INTL BRASIL S.A."
  },
  {
    value: "748",
    label: "BANCO COOPERATIVO SICREDI S.A.",
    shortName: "BCO COOPERATIVO SICREDI S.A."
  },
  {
    value: "751",
    label: "Scotiabank Brasil S.A. Banco Múltiplo",
    shortName: "SCOTIABANK BRASIL"
  },
  {
    value: "752",
    label: "Banco BNP Paribas Brasil S.A.",
    shortName: "BCO BNP PARIBAS BRASIL S A"
  },
  {
    value: "753",
    label: "Novo Banco Continental S.A. - Banco Múltiplo",
    shortName: "NOVO BCO CONTINENTAL S.A. - BM"
  },
  {
    value: "754",
    label: "Banco Sistema S.A.",
    shortName: "BANCO SISTEMA"
  },
  {
    value: "755",
    label: "Bank of America Merrill Lynch Banco Múltiplo S.A.",
    shortName: "BOFA MERRILL LYNCH BM S.A."
  },
  {
    value: "756",
    label: "BANCO COOPERATIVO SICOOB S.A. - BANCO SICOOB",
    shortName: "BANCO SICOOB S.A."
  },
  {
    value: "757",
    label: "BANCO KEB HANA DO BRASIL S.A.",
    shortName: "BCO KEB HANA DO BRASIL S.A."
  }
] as const
