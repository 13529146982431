export const RG_EMITTERS = [
  { rgEmitter: 'SSP', description: 'Secretaria de Segurança Pública' },
  { rgEmitter: 'Cartório Civil', description: ''},
  { rgEmitter: 'Policia Federal', description: '' },
  { rgEmitter: 'Detran', description: 'Departamento Estadual de Trânsito' },
  { rgEmitter: 'ABNC', description: 'Academia Brasileira de Neurocirurgia' },
  {
    rgEmitter: 'CGPI/DUREX/DPF',
    description: 'Coordenação Geral de Polícia de Imigração da Polícia Federal'
  },
  { rgEmitter: 'CGPI', description: 'Coordenação-Geral de Privilégios e Imunidades' },
  {
    rgEmitter: 'CGPMAF',
    description: 'Coordenadoria Geral de Polícia Marítima, Aeronáutica e de Fronteiras'
  },
  { rgEmitter: 'CNIG', description: 'Conselho Nacional de Imigração' },
  { rgEmitter: 'CNT', description: 'Confederação Nacional de Tranporte' },
  { rgEmitter: 'COREN', description: 'Conselho Regional de Enfermagem' },
  { rgEmitter: 'CORECON', description: 'Conselho Regional de Economia' },
  { rgEmitter: 'CRA', description: 'Conselho Regional de Administração' },
  { rgEmitter: 'CRAS', description: 'Centro de Referência de Assistência Social' },
  { rgEmitter: 'CRB', description: 'Conselho Regional de Biblioteconomia' },
  { rgEmitter: 'CRC', description: ' Conselho Regional de Contabilidade' },
  { rgEmitter: 'CRE', description: 'Conselho Regional de Estatística' },
  { rgEmitter: 'CREA', description: 'Conselho Regional de Engenharia e Agronomia' },
  { rgEmitter: 'CRECI', description: 'Conselho Regional de Corretores de Imóveis' },
  {
    rgEmitter: 'CREFIT',
    description: 'Conselho Regional de Fisioterapia e Terapia Ocupacional'
  },
  { rgEmitter: 'CRESS', description: 'Conselho Regional de Serviço Social' },
  { rgEmitter: 'CRF', description: 'Conselho Regional de Farmácia' },
  { rgEmitter: 'CRM', description: 'Conselho Regional de Medicina' },
  { rgEmitter: 'CRN', description: 'Conselho Regional de Nutrição' },
  { rgEmitter: 'CRO', description: 'Conselho Regional de Odontologia' },
  { rgEmitter: 'CRP', description: 'Conselho Regional de Psicologia' },
  {
    rgEmitter: 'CRPRE',
    description: 'Conselho Regional de Profissionais de Relações Públicas'
  },
  { rgEmitter: 'CRQ', description: 'Conselho Regional de Química' },
  { rgEmitter: 'CRRC', description: 'Conselho Regional de Representantes Comerciais' },
  { rgEmitter: 'CRMV', description: 'Conselho Regional de Medicina Veterinária' },
  { rgEmitter: 'CSC', description: 'Carteira Sede Carpina de Pernambuco' },
  { rgEmitter: 'CTPS', description: 'Carteira de Trabalho e Previdência Social' },
  { rgEmitter: 'DIC', description: 'Diretoria de Identificação Civil' },
  { rgEmitter: 'DIREX', description: 'Diretoria-Executiva' },
  { rgEmitter: 'DPMAF', description: 'Divisão de Polícia Marítima, Área e de Fronteiras' },
  { rgEmitter: 'DPT', description: 'Departamento de Polícia Técnica Geral' },
  { rgEmitter: 'DST', description: 'Programa Municipal DST/Aids' },
  { rgEmitter: 'FGTS', description: 'Fundo de Garantia do Tempo de Serviço' },
  { rgEmitter: 'FIPE', description: 'Fundação Instituto de Pesquisas Econômicas' },
  { rgEmitter: 'FLS', description: 'Fundação Lyndolpho Silva' },
  { rgEmitter: 'GOVGO', description: 'Governo do Estado de Goiás' },
  { rgEmitter: 'CLA', description: 'Carteira de Identidade Classista' },
  { rgEmitter: 'IFP', description: 'Instituto Félix Pacheco' },
  { rgEmitter: 'IGP', description: 'Instituto Geral de Perícias' },
  {
    rgEmitter: 'IICCECF/RO',
    description:
      'Instituto de Identificação Civil e Criminal Engrácia da Costa Francisco de Rondônia'
  },
  { rgEmitter: 'IIMG', description: 'Inter-institutional Monitoring Group' },
  { rgEmitter: 'IML', description: 'Instituto Médico-Legal' },
  { rgEmitter: 'IPC', description: 'Índice de Preços ao Consumidor' },
  { rgEmitter: 'IPF', description: 'Instituto Pereira Faustino' },
  { rgEmitter: 'MAE', description: 'Ministério da Aeronáutica' },
  { rgEmitter: 'MEX', description: 'Ministério do Exército' },
  { rgEmitter: 'MMA', description: 'Ministério da Marinha' },
  { rgEmitter: 'OAB', description: 'Ordem dos Advogados do Brasil' },
  { rgEmitter: 'OMB', description: 'Ordens dos Músicos do Brasil' },
  { rgEmitter: 'PCMG', description: 'Polícia Civil do Estado de Minas Gerais' },
  { rgEmitter: 'PMMG', description: 'Polícia Militar do Estado de Minas Gerais' },
  { rgEmitter: 'POF', description: 'Polícia Federal' },
  { rgEmitter: 'POM', description: 'Polícia Militar' },
  { rgEmitter: 'SDS', description: 'Secretaria de Defesa Social (Pernambuco)' },
  {
    rgEmitter: 'SNJ',
    description: 'Secretaria Nacional de Justiça / Departamento de Estrangeiros'
  },
  { rgEmitter: 'SECC', description: 'Secretaria de Estado da Casa Civil' },
  {
    rgEmitter: 'SEJUSP',
    description: 'Secretaria de Estado de Justiça e Segurança Pública'
  },
  { rgEmitter: 'SES ou EST', description: 'Carteira de Estrangeiro' },
  { rgEmitter: 'SESP', description: 'Secretaria de Estado da Segurança Pública' },
  { rgEmitter: 'SJS', description: 'Secretaria da Justiça e Segurança' },
  { rgEmitter: 'SJTC', description: 'Secretaria da Justiça do Trabalho e Cidadania' },
  { rgEmitter: 'SJTS', description: 'Secretaria da Justiça do Trabalho e Segurança' },
  { rgEmitter: 'SPTC', description: 'Secretaria de Polícia Técnico-Científica' }
] as const
