import logoMainIcon from './images/logo.svg'
import logoWhiteIcon from './images/logo-white.svg'
import logoShortIcon from './images/logo-short.svg'
import colors from '../../colors'

export const PAKETA = {
  tenant: {
    title: 'Paketá - Empréstimo 100% online | Simule agora!',
    name: 'Paketá',
    favicon: 'favicon-paketa.png',
    logo: {
      new: logoMainIcon,
      main: logoMainIcon,
      negative: logoWhiteIcon,
      white: logoWhiteIcon,
      short: logoShortIcon,
      error: logoMainIcon,
    },
  },
  palette: {
    primary: {
      main: '#0062FF',
      dark: '#004AC2',
      light: '#7AADFF',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#44444F',
      light: '#92DDF3',
      contrastText: '#FFF',
      dark: '#192145',
    },
    error: {
      main: '#FC5A5A',
    },
    warning: {
      main: '#FFC542',
    },
    success: {
      main: '#00B894',
    },
    colors
  },
}
